import { ReportedHastagActionType } from 'store/constants/ReportedHastagActionType';

const reportHasgtagInitialState = {
  hashtagsreports: [],
  count: 0,
  status: 'init',
  hashtagPostDeatils: [],
};

const ReportedHasgtagReducer = (state = reportHasgtagInitialState, action = {}) => {
  switch (action.type) {
    case ReportedHastagActionType.GET_REPORTED_HASHTAGS: {
      return {
        ...state,
        hashtagsreports: action.payload.hashtags,
        count: action.payload.count,
        status: 'added',
      };
    }
    case ReportedHastagActionType.FILTER_REPORTED_HASHTAGS_FULFILLED: {
      return {
        ...state,
        hashtagsreports: action.payload.hashtags,
        status: 'updated_hashtagsreports',
        count: action.payload.count,
      };
    }
    case ReportedHastagActionType.GET_POST_DETAILS: {
      return {
        ...state,
        hashtagPostDeatils: action.payload.hashtag,
        status: 'updated_hashtagsreports',
      };
    }
    case ReportedHastagActionType.ADD_HASHTAGS_POST: {
      return {
        ...state,
        status: 'updated_hashtagsreports',
      };
    }
    case ReportedHastagActionType.REMOVE_HASHTAGS_POST: {
      return {
        ...state,
        status: 'updated_hashtagsreports',
      };
    }
    default:
      return state;
  }
};

export { ReportedHasgtagReducer, reportHasgtagInitialState };
