import { PostActionType } from 'store/constants/PostActionType';

const postInitialState = {
  post: null,
  status: 'init',
  count: 1,
};

const PostReducer = (state = postInitialState, action = {}) => {
  switch (action.type) {
    case PostActionType.GET_POST_FULFILLED:
      return {
        ...state,
        post: action.payload.attributes,
        status: 'added',
      };
    default:
      return state;
  }
};

export { PostReducer, postInitialState };
