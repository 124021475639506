import React, { useEffect, useContext, useState, useRef } from 'react';
import { Button, TextField, FormGroup } from '@material-ui/core';
// for flash message
import * as commonService from 'utils/CommonService';
import * as HahtagAction from 'store/actions/HashtagAction';
import { store } from 'store';
//import {BODY_PARTS} from "config";
import DrawerModel from 'components/shared/ui-components/drawer-model/DrawerModel';
import SimpleReactValidator from 'simple-react-validator';

const PageForm = ({ setCurrentHashtag, currentHashtag }) => {
  //const classes = useStyles();
  const globalState = useContext(store);
  const { hashtagDispatch } = globalState.dispatch;
  let defaultForm = {
    name: '',
  };
  const [state, setState] = React.useState({ open: false });
  const [form, setForm] = React.useState({ ...defaultForm });

  //initialize validations
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    if (currentHashtag && !state.open) {
      setState({ open: true });
      setForm(currentHashtag);
    }
  }, [currentHashtag, state]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (currentHashtag) setCurrentHashtag(null);
    setState({ ...state, open });
    setForm(defaultForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (simpleValidator.current.allValid()) {
      const name = form.name.charAt(0) === '#' ? form.name : `#${form.name}`;
      if (form.id) {
        HahtagAction.updateHashtag({
          body: { hashtag: { name } },
          id: form.id,
          dispatch: hashtagDispatch,
        }).then((res) => {
          setForm({ ...defaultForm });
          if (currentHashtag) setCurrentHashtag(null);
          commonService.forSuccess('Hashtag updated successfully!', 'Success');
          setState({ ...state, open: false });
          setForm({ ...defaultForm });
        });
      } else {
        HahtagAction.createHashtag({
          body: { hashtag: { name } },
          dispatch: hashtagDispatch,
        }).then((res) => {
          commonService.forSuccess('Hashtag created successfully!', 'Success');
          setState({ ...state, open: false });
          setForm({ ...defaultForm });
        });
      }
    } else {
      simpleValidator.current.showMessages(); //show validation messages
      forceUpdate(1);
    }
  };

  const handleChange = (target) => {
    setForm({ ...form, [target.name]: target.value });
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className="btn-theme-primary mb-3"
        onClick={toggleDrawer(true)}
      >
        Add Hashtag
      </Button>

      <DrawerModel toggleDrawer={toggleDrawer} open={state.open}>
        <form className="login100-form p-3" id="login_form" onSubmit={(e) => handleSubmit(e)}>
          <div className="inner_form">
            <div className="fields">
              <h6 className="mb-3 text-center">{currentHashtag ? 'Edit' : 'New'} Hashtag</h6>
              <div className="row p-2 sugg-create-wrapper">
                <FormGroup className="col-12 pt-2 pb-2 ">
                  <TextField
                    variant="outlined"
                    label="name"
                    name="name"
                    required
                    // value={form.name}
                    value={
                      form.name && form.name.charAt(0) === '#'
                        ? form.name.slice(1, form.name.length)
                        : form.name
                    }
                    onChange={(e) => handleChange(e.target)}
                    onBlur={() => simpleValidator.current.showMessageFor('name')}
                  />
                  <span className="hash-tag">#</span>
                </FormGroup>
                {form.name ? (
                  <span style={{ color: '#fff' }}>
                    {simpleValidator.current.message(
                      'name',
                      form.name && form.name.charAt(0) === '#'
                        ? form.name.slice(1, form.name.length)
                        : form.name,
                      'required|alpha_num_space',
                    )}
                  </span>
                ) : (
                  ''
                )}
              </div>

              <div className="ml-2 mt-3">
                <Button
                  variant="contained"
                  color="default"
                  className="text-uppercase btn mr-2"
                  type="button"
                  onClick={toggleDrawer(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="text-uppercase btn btn-primary "
                  id="submit"
                  type="submit"
                >
                  {currentHashtag ? 'Update' : 'Add'}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </DrawerModel>
    </div>
  );
};

export default PageForm;
