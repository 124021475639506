import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, ButtonGroup, Avatar } from '@material-ui/core';
import CommonTable from 'components/shared/common-table/CommonTable';
import { TextTruncate } from 'utils/TextTruncate';
import { store } from 'store';
import * as FeaturedPostAction from 'store/actions/FeaturedPostActions';
import '../../../../node_modules/bootstrap/dist/js/bootstrap';

const FeaturedPostTable = ({ totalCount }) => {
  const [featuredpostData, setFeaturedPostData] = useState([]);
  const keys = ['id', 'Username', 'Link', 'About', 'status'];

  const history = useHistory();
  const [pagination, setDefaultPagination] = useState({
    page: history.location.state && history.location.state.page ? history.location.state.page : 1,
    limit:
      history.location.state && history.location.state.limit ? history.location.state.limit : 10,
    // approvedStatus: "accepted"
    approvedStatus: 'requested',
  });

  const {
    dispatch: { featuredPostDispatch: dispatch },
    state: { featuredPostState },
  } = useContext(store);
  const { featuredPostList, status, count, isLoaded } = featuredPostState;
  const [rowData, setRowData] = useState([]);

  const [filterParams] = useState({});
  const [loaded, setLoaded] = useState(false);

  const approvePost = (ID, type) => {
    if (type === 'accept') FeaturedPostAction.approvePost(ID, { dispatch });
    else FeaturedPostAction.rejectPost(ID, { dispatch });
  };

  const featuredpostTableData = useCallback(() => {
    return featuredPostList.map((post) => {
      return [
        post.id,
        <div className="d-inline user-img">
          <Avatar
            alt="Natacha"
            className="float-left mr-1"
            src={post.photo_urls && post.photo_urls.medium}
          />
          <Link
            to={{
              pathname: `/users/${post.id}/details`,
              state: {
                page: pagination.page,
                limit: pagination.limit,
                path: history.location.pathname,
              },
            }}
          >
            {post.user_name}
          </Link>
        </div>,
        <a href={post.approval.link} target="_blank">
          {TextTruncate({ str: post.approval.link, limit: 20 })}
        </a>,

        TextTruncate({ str: post.approval.about, limit: 20 }),

        post.approval.status === 'requested' ? (
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button
              color="primary"
              style={{ paddingRight: '12%' }}
              onClick={(a) => {
                approvePost(post.approval.id, 'accept');
                FeaturedPostAction.userFirstTimeApproved(post.id, {
                  dispatch,
                });
              }}
            >
              Accept
            </Button>
            <Button
              color="secondary"
              // onClick={a => approvePost(post.id, "reject")}
              onClick={() => history.push(`/users/${post.id}/details`)}
            >
              REQUEST TO REAPPLY
            </Button>
          </ButtonGroup>
        ) : (
          post.approval.status
        ),
      ];
    });
  }, [featuredPostList, pagination.page, pagination.limit, history, approvePost, dispatch]);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      if (!isLoaded) FeaturedPostAction.getFeaturedPosts({ body: { ...pagination }, dispatch });
    }
  }, [rowData, featuredPostList, status, pagination, loaded, isLoaded, dispatch]);

  useEffect(() => {
    if (rowData.length !== featuredPostList.length || status !== 'init') {
      const rows = featuredPostList;
      setRowData(rows);
    }
  }, [rowData, status, featuredPostList]);

  const setPagination = (params) => {
    setDefaultPagination({ ...pagination, ...params });
    FeaturedPostAction.getFeaturedPosts({
      body: { ...pagination, ...params, ...filterParams },
      dispatch,
    });
  };

  useEffect(() => {
    let featuredpostRowData = featuredpostTableData(featuredPostList);
    if (JSON.stringify(featuredpostRowData) !== JSON.stringify(featuredpostData)) {
      setFeaturedPostData(featuredpostRowData);
    }
  }, [featuredpostTableData, featuredPostList, featuredpostData]);
  return (
    <CommonTable
      totalCount={count}
      customPagination={pagination}
      setCustomPagination={setPagination}
      data={featuredpostData}
      keys={keys}
    ></CommonTable>
  );
};

export default FeaturedPostTable;
