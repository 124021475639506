import React, { useState, useContext, useRef } from 'react';
import * as userAuthAction from 'store/actions/AuthActions';
import { Link } from 'react-router-dom';
import { store } from 'store';
import * as commonService from 'utils/CommonService.js';
import SimpleReactValidator from 'simple-react-validator';

const LoginForm = ({ history }) => {
  const [user, setUser] = useState({ user: '', password: '' });
  const globalState = useContext(store);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const { userAuthDispatch } = globalState.dispatch;
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    forceUpdate(1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      var updatedUser = { ...user, session_info: { platform: 'web' } };
      userAuthAction
        .createSession({ body: { user: updatedUser }, dispatch: userAuthDispatch })
        .then((res) => {
          if (res.user.role !== 'admin') {
            userAuthDispatch({
              type: 'REMOVE_AUTH_TOKEN',
              payload: { authToken: null },
            });
            commonService.forError('Only admin can access this site!', 'Error');
          } else {
            commonService.forSuccess('Signed in successfully', 'Success');
            history.push('/');
          }
        });
    } else {
      simpleValidator.current.showMessages(); //show validation messages
      forceUpdate(1);
    }
  };

  return (
    <div className="limiter">
      <div className="container-login100">
        <div id="wrap" className="mt_0">
          <div className="lps_container mt_0">
            <div className="lps_flx_vm_jc lps_bg_txt_white lps_bg_secondary on_boarding_wrp on_boardingNChng">
              <div className="lps_form_wrp">
                <form onSubmit={handleSubmit}>
                  <article className="text_center lps_logo_center">
                    <Link className="logo mb_0" to="#">
                      <img
                        src={require('assets/images/thumbnails/lips-logo-icon.svg')}
                        alt="LipsLogo"
                        className="header__logo"
                      />
                      <h5 className="text_uppercase text_white">Sign In</h5>
                    </Link>
                  </article>
                  <div className="lps_fields">
                    <div className="form_group_modify">
                      <input
                        type="email"
                        name="user"
                        className="input_modify"
                        placeholder="email"
                        value={user.user}
                        onBlur={() => simpleValidator.current.showMessageFor('user')}
                        onChange={(e) =>
                          handleChange({
                            target: { name: e.target.name, value: e.target.value.trim() },
                          })
                        }
                      />
                      {simpleValidator.current.message('email', user.user, 'required|email')}
                    </div>

                    <div className="form_group_modify lps_pos_rltv">
                      <input
                        type={passwordShown ? 'text' : 'password'}
                        name="password"
                        className="input_modify"
                        placeholder="Password"
                        value={user.password}
                        onChange={handleChange}
                        onBlur={() => simpleValidator.current.showMessageFor('password')}
                      />
                      {simpleValidator.current.message('password', user.password, 'required')}
                      <span className="icn_passAbslt">
                        {passwordShown ? (
                          <img
                            onClick={togglePasswordVisiblity}
                            src={require('assets/images/icon/icn_hide_white.png')}
                            alt="pass1"
                          />
                        ) : (
                          <img
                            onClick={togglePasswordVisiblity}
                            src={require('assets/images/icon/icb_eye_white.png')}
                            alt="pass2"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="pos_wrp onboarding_btm smalBtn">
                    <button
                      type="submit"
                      className="theme_btn theme_primary btn_block theme_btn_rds25 text_uppercase"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
