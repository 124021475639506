import React, { useContext, useEffect, useState, useCallback } from 'react';

import { store } from 'store';

import * as ReportedHashtagActions from 'store/actions/ReportedHastagAction';
import * as HashtagAction from 'store/actions/HashtagAction';

import ReportsTable from './ReportsTable';
import Filter from '../shared/ui-components/filter/filter';
import ReportDetails from './ReportDetails';

import { CSVLink } from 'react-csv';

import { REQUEST_STATUS } from 'config';

import '../reportedPosts/report.css';

const ReportedTags = () => {
  const [pagination, setPagination] = useState({ page: 1, limit: 10, orderBy: 'DESC' });
  const globalState = useContext(store);
  const { reportHashtagDispatch, hashtagDispatch } = globalState.dispatch;
  const { hashtagList } = globalState.state.hashtagState;
  const { hashtagsreports, count, status, hashtagPostDeatils } =
    globalState.state.reportHashtagState;
  const [filterParams, setFilterParams] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [loadedif, setLoadedif] = useState(false);
  const [isShown, setIsShownn] = useState(false);

  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    if (status !== 'updated_hashtagsreports') {
      ReportedHashtagActions.getSuggestedHashtags({
        params: pagination,
        dispatch: reportHashtagDispatch,
      });
    }
  }, [reportHashtagDispatch]);

  const rejectReportedTag = async (id, body) => {
    await ReportedHashtagActions.rejectHashtag({
      body,
      id,
      dispatch: reportHashtagDispatch,
    });

    await ReportedHashtagActions.getSuggestedHashtags({
      params: pagination,
      dispatch: reportHashtagDispatch,
    });
  };

  const approveReportedTag = async (currentReport, body) => {
    let id = currentReport.id;
    await ReportedHashtagActions.approveHashtag({
      body,
      id,
      dispatch: reportHashtagDispatch,
    });

    await ReportedHashtagActions.getSuggestedHashtags({
      params: pagination,
      dispatch: reportHashtagDispatch,
    });
  };

  const handlePagination = (params) => {
    setPagination({ ...pagination, ...params });
    ReportedHashtagActions.getSuggestedHashtags({
      params: { ...pagination, ...params },
      dispatch: reportHashtagDispatch,
    });
  };

  const handleFilter = (e, filterForm) => {
    e.preventDefault();
    setPagination({ ...pagination, page: 1 });
    setFilterParams(filterForm);
    ReportedHashtagActions.filterSuggestedHashtags({
      params: { ...filterForm },
      dispatch: reportHashtagDispatch,
    });
  };

  const getPostDetails = async (feed) => {
    // HashtagAction.getHashtags({ body: { page: 1, limit: 10000000, orderBy: "ASC" }, dispatch: hashtagDispatch });
    await ReportedHashtagActions.getPostDetails({
      id: feed.id,
      dispatch: reportHashtagDispatch,
    });

    await setIsShownn(true);
  };

  const addTagsInPost = async (feed, hashtag) => {
    const body = { add_hashTags: hashtag };
    await ReportedHashtagActions.addTagsInPost({
      id: feed.id,
      body,
      dispatch: reportHashtagDispatch,
    });

    await ReportedHashtagActions.getSuggestedHashtags({
      params: pagination,
      dispatch: reportHashtagDispatch,
    });
  };

  const removeTagsInPost = async (feed, hashtag) => {
    const body = { remove_hashTags: hashtag };
    await ReportedHashtagActions.removeTagsInPost({
      id: feed.id,
      body,
      dispatch: reportHashtagDispatch,
    });

    await ReportedHashtagActions.getSuggestedHashtags({
      params: pagination,
      dispatch: reportHashtagDispatch,
    });
  };

  const resetLeadFilterForm = (data) => {
    setFilterParams(data);
    setPagination({ page: 1, limit: 10 });
    ReportedHashtagActions.getSuggestedHashtags({
      params: pagination,
      dispatch: reportHashtagDispatch,
    });
  };

  const requestCount = (report) => {
    setLoadedif(true);
    let resolvedReports = 0;
    let activeReports = 0;

    if (report.hastag_suggest_post) {
      report.hastag_suggest_post.map((requestedTag) => {
        if (requestedTag.status === 'suggested') {
          resolvedReports = resolvedReports + 1;
        } else {
          activeReports = activeReports + 1;
        }
      });
      return `${resolvedReports} active`, `${activeReports} resolved`;
    }
    setLoadedif(false);
  };

  return (
    <>
      {isShown ? (
        <ReportDetails
          hashtagPostDeatils={hashtagPostDeatils}
          approveReportedTag={approveReportedTag}
          addTagsInPost={addTagsInPost}
          hashtagList={hashtagList}
          removeTagsInPost={removeTagsInPost}
          rejectReportedTag={rejectReportedTag}
          setIsShownn={setIsShownn}
        ></ReportDetails>
      ) : (
        <div className="report-page-container">
          <Filter
            handleFilter={handleFilter}
            resetLeadFilterForm={resetLeadFilterForm}
            str="reportHashtag"
          ></Filter>
          <ReportsTable
            {...{
              hashtagsreports,
              rejectReportedTag,
              approveReportedTag,
              // revertReportedTag,
              getPostDetails,
              hashtagPostDeatils,
              pagination,
              setPagination: handlePagination,
              totalCount: count,
              isShown: isShown,
              setIsShownn: setIsShownn,
            }}
          />
        </div>
      )}
    </>
  );
};

export default ReportedTags;
