export const UsersActionType = {
  GET_USERS_FULFILLED: 'GET_USERS_FULFILLED',

  APPROVED_USER_FULFILLED: 'APPROVED_USER_FULFILLED',

  DEACTIVATE_USER_FULFILLED: 'DEACTIVATE_USER_FULFILLED',

  FILTER_USERS_FULFILLED: 'FILTER_USERS_FULFILLED',

  GET_USER: 'GET_USER',
  GET_USER_FULFILLED: 'GET_USER_FULFILLED',
  DELETE_USERS_FULFILLED: 'DELETE_USER_FULFILLED',
  GET_USERS_DATA_FULFILLED: 'GET_USERS_DATA_FULFILLED',

  REAPPLY_APPROVED_USER_FULFILLED: 'REAPPLY_APPROVED_USER_FULFILLED',
  PAST_APPROVAL_REQUESTS_FULFILLED: 'PAST_APPROVAL_REQUESTS_FULFILLED',
};
