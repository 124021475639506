import React, { useState, useEffect, useContext } from 'react';
import { store } from 'store';
import * as CodeAction from 'store/actions/CouponCodeActions';
import CouponCodeTable from './CouponCodeList.js';
import Form from '../CouponCodeComponent/Form';

const CouponCodeList = () => {
  const defaultPagination = {
    page: 1,
    limit: 10,
  };
  const globalState = useContext(store);
  const { couponcodeDispatch } = globalState.dispatch;
  const { couponcodeList, status } = globalState.state.couponcodeState;
  const [rowData, setRowData] = useState([]);
  const [currentCouponCode, setCurrentCouponCode] = useState(null);
  const [pagination] = useState(defaultPagination);
  const [loaded, setLoaded] = useState(false);
  //const [filterParams] = useState({});

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      CodeAction.getCouponCodes({ body: { ...pagination }, dispatch: couponcodeDispatch });
    }
  }, [loaded, couponcodeDispatch, pagination]);

  useEffect(() => {
    if (rowData.length !== couponcodeList.length || status !== 'init') {
      const rows = couponcodeList;
      setRowData(rows);
    }
  }, [rowData, status, couponcodeList]);

  //const setPagination = (params) => {
  //  setDefaultPagination({...pagination,...params});
  //  CodeAction.getCouponCodes({ body: {...pagination,...params,...filterParams}, dispatch: couponcodeDispatch });
  //};
  return (
    <>
      <div className="p-2">
        <div className="row">
          <div className="col-md-8">
            <Form
              currentCouponCode={currentCouponCode}
              setLoaded={setLoaded}
              setCurrentCouponCode={setCurrentCouponCode}
            ></Form>
          </div>
        </div>
        <CouponCodeTable
          setCurrentCouponCode={setCurrentCouponCode}
          couponcodeList={rowData}
          couponcodeDispatch={couponcodeDispatch}
        ></CouponCodeTable>
      </div>
    </>
  );
};

export default CouponCodeList;
