import React, { useState, useEffect, useCallback } from 'react';
import CommonTable from 'components/shared/common-table/CommonTable';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
const FeaturedAdTable = ({ totalCount, pagination, setPagination, featuredAdList }) => {
  const [featuredAdData, setFeaturedAdData] = useState([]);
  const history = useHistory();

  //    const toggleActive = useCallback(
  //     (checked, user) => {
  //       //user.active_status = checked ? 1 : 0;
  //       UsersAction.toggleActive({ body: { active: checked } , id: user.id, dispatch: usersDispatch });
  //     },
  //     [UsersAction, usersDispatch]
  //   );

  const keys = ['id', 'title', 'description', 'Action'];

  const featuredAdTableData = useCallback(() => {
    return featuredAdList.map((featured) => {
      return [
        featured.id,
        featured.title,
        featured.description,
        <Button
          onClick={() => history.push(`/featured-ad/${featured.id}/edit`)}
          variant="contained"
          color="primary"
        >
          Edit
        </Button>,
      ];
    });
  }, [featuredAdList, history]);

  useEffect(() => {
    let featuredAdRowData = featuredAdTableData(featuredAdList);
    if (JSON.stringify(featuredAdRowData) !== JSON.stringify(featuredAdData)) {
      setFeaturedAdData(featuredAdRowData);
    }
  }, [featuredAdTableData, featuredAdList, featuredAdData]);
  return (
    <CommonTable
      totalCount={totalCount}
      customPagination={pagination}
      setCustomPagination={setPagination}
      data={featuredAdData}
      keys={keys}
    ></CommonTable>
  );
};

export default FeaturedAdTable;
