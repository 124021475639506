import { CouponCodeActionType } from 'store/constants/CouponCodeActionType';

const couponcodesInitialState = {
  couponcodeList: [],
  status: 'init',
  count: 1,
};

const sortUsers = (styles) => {
  return styles.sort((newStyle, oldStyle) => oldStyle.id - newStyle.id);
};

const CouponCodeReducer = (state = couponcodesInitialState, action = {}) => {
  switch (action.type) {
    case CouponCodeActionType.GET_COUPON_CODE:
      return {
        ...state,
        couponcodeList: sortUsers(action.payload.codes),
        // count: action.payload.count,
        status: 'added',
      };

    case CouponCodeActionType.ADD_COUPON_CODE:
      return {
        ...state,
        couponcodeList: sortUsers([action.payload, ...state.couponcodeList]),
        status: 'init',
      };

    default:
      return state;
  }
};

export { CouponCodeReducer, couponcodesInitialState };
