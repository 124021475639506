import React, { useState, useEffect, useContext } from 'react';
import { store } from 'store';
import * as FeaturedPostAction from 'store/actions/FeaturedPostActions';
import FeaturedPostTable from './FeaturedPostRequestedList/FeaturedPostList.js';
import FeaturedPostTable2 from './FeaturedPostApprovedList/FeaturedPostList';

import { useHistory } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/js/bootstrap';

const FeaturedPostList = () => {
  return (
    <>
      <section style={{ height: 3000 }} class="tabs-section">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="pills-pending-approvals-tab"
                    data-toggle="pill"
                    href="#pills-pending-approvals"
                    role="tab"
                    aria-controls="pills-pending-approvals"
                    aria-selected="true"
                  >
                    Pending approvals
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-approved-users-tab"
                    data-toggle="pill"
                    href="#pills-approved-users"
                    role="tab"
                    aria-controls="pills-approved-users"
                    aria-selected="false"
                  >
                    Approved users
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-pending-approvals"
                  role="tabpanel"
                  aria-labelledby="pills-pending-approvals-tab"
                >
                  <FeaturedPostTable></FeaturedPostTable>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-approved-users"
                  role="tabpanel"
                  aria-labelledby="pills-approved-users-tab"
                >
                  <FeaturedPostTable2></FeaturedPostTable2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturedPostList;
