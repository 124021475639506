// user api
import Axios from 'axios';
import * as authApi from 'api';
import { UserAuthActionType } from 'store/constants/AuthActionType';

export const createSession = ({ body, dispatch }) => {
  return new Promise((resolve, reject) => {
    authApi.createSession(body).then((res) => {
      Axios.defaults.headers.common['Authorization'] = `Bearer ${res.token}`;
      dispatch({
        type: UserAuthActionType.GET_AUTH_TOKEN_FULFILLED,
        payload: res,
      });
      resolve(res);
    });
  });
};
