import React, { useState, useEffect, useCallback } from 'react';
import { Avatar } from '@material-ui/core';
import CommonTable from 'components/shared/common-table/CommonTable';

import ReportModel from './ReportModel';

const ReportsTable = ({
  hashtagsreports,
  deleteReportedTag,
  approveReportedTag,
  hashtagPostDeatils,
  // revertReportedTag,
  getPostDetails,
  pagination,
  setPagination,
  totalCount,
  isShown,
  setIsShownn,
}) => {
  const [currentReport, setCurrentReport] = useState({});
  const [reportsData, setReportsData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  var removeTagAdminReqArray = 0;

  const keys = ['id', 'Post author', 'Post privacy', 'Pending', 'Saved', 'Solved', 'Action'];

  const savedRequestCount = (report) => {
    setLoaded(true);
    let savedAddReports = localStorage.getItem(`${report.id} Add`, null);
    let savedRemoveReports = localStorage.getItem(`${report.id} Remove`, null);
    let savedRemoveIgnoreReports = localStorage.getItem(`${report.id} RemoveIgnore`, null);
    let savedAddIgnoreReports = localStorage.getItem(`${report.id} AddIgnore`, null);
    let savedRemoveTagAdminReports = localStorage.getItem(`${report.id} RemoveTagAdmin`, null);

    var addReqArray = savedAddReports ? savedAddReports.split(',').map(String).length : 0;
    var removeReqArray = savedRemoveReports ? savedRemoveReports.split(',').map(String).length : 0;
    var addIgnoreReqArray = savedAddIgnoreReports
      ? savedAddIgnoreReports.split(',').map(String).length
      : 0;
    var removeIgnoreReqArray = savedRemoveIgnoreReports
      ? savedRemoveIgnoreReports.split(',').map(String).length
      : 0;
    removeTagAdminReqArray = savedRemoveTagAdminReports
      ? savedRemoveTagAdminReports.split(',').map(String).length
      : 0;

    setLoaded(false);
    return (
      addReqArray +
      removeReqArray +
      addIgnoreReqArray +
      removeIgnoreReqArray +
      removeTagAdminReqArray
    );
  };

  let approveTagSuggest = [];
  let approveTagApprove = [];
  let approveTagReject = [];
  let RemoveTagSuggest = [];
  let RemoveTagApprove = [];
  let RemoveTagReject = [];
  let approveRemoveByAdmin = [];

  const pendingRequestCount = (savedRequestCount, report) => {
    let pendingRequests = 0;

    setLoaded(true);

    const addReqCount = () => {
      let allTags = [];

      if (report.hastag_suggest_post) {
        report.hastag_suggest_post.map((e) => {
          e.suggest_tag.map((f) => {
            if (f.status === 'hold' && e.suggest_stauts === 'approved_tag') {
              return allTags.push(Object.assign(f));
            }
          });
        });

        const counts = {};
        allTags.forEach((x) => {
          counts[x.hashtag_name] = (counts[x.hashtag_name] || 0) + 1;
        });

        let uniqueArray = [...new Map(allTags.map((v) => [v.hashtag_name, v])).values()];

        approveTagSuggest = uniqueArray.map((e, i) => {
          let keys = Object.keys(counts);
          keys.forEach((key) => {
            if (e.hashtag_name === key) {
              Object.assign(e, { count: counts[key] });
            }
          });
          return e;
        });
      }
      return approveTagSuggest.length;
    };

    const removeReqCount = () => {
      let allTags = [];

      if (report.hastag_suggest_post) {
        report.hastag_suggest_post.map((e) => {
          e.suggest_tag.map((f) => {
            if (f.status === 'hold' && e.suggest_stauts === 'remove_tag') {
              return allTags.push(Object.assign(f));
            }
          });
        });
        const counts = {};
        allTags.forEach((x) => {
          counts[x.hashtag_name] = (counts[x.hashtag_name] || 0) + 1;
        });

        let uniqueArray = [...new Map(allTags.map((v) => [v.hashtag_name, v])).values()];

        RemoveTagSuggest = uniqueArray.map((e, i) => {
          let keys = Object.keys(counts);
          keys.forEach((key) => {
            if (e.hashtag_name === key) {
              Object.assign(e, { count: counts[key] });
            }
          });
          return e;
        });
      }
      return RemoveTagSuggest.length;
    };

    if (report.hastag_suggest_post) {
      report.hastag_suggest_post.map((requestedTag) => {
        requestedTag.suggest_tag.map((tag) => {
          if (tag.status === 'hold') {
            pendingRequests = pendingRequests + 1;
          }
        });
      });
      setLoaded(false);
      return addReqCount() + removeReqCount() - savedRequestCount + removeTagAdminReqArray < 0
        ? 0
        : addReqCount() + removeReqCount() - savedRequestCount + removeTagAdminReqArray;
    }
  };

  const solvedRequestCount = (report) => {
    const solvedRemoveTagReq = () => {
      let allTags = [];

      if (report.hastag_suggest_post) {
        report.hastag_suggest_post.map((e) => {
          e.suggest_tag.map((f) => {
            if (f.status === 'approved' && e.suggest_stauts === 'approved_tag') {
              return allTags.push(Object.assign(f));
            }
          });
        });

        const counts = {};
        allTags.forEach((x) => {
          counts[x.hashtag_name] = (counts[x.hashtag_name] || 0) + 1;
        });

        let uniqueArray = [...new Map(allTags.map((v) => [v.hashtag_name, v])).values()];

        approveTagApprove = uniqueArray.map((e, i) => {
          let keys = Object.keys(counts);
          keys.forEach((key) => {
            if (e.hashtag_name === key) {
              Object.assign(e, { count: counts[key] });
            }
          });
          return e;
        });
      }
      return approveTagApprove.length;
    };

    const solvedAddTagReq = () => {
      let allTags = [];

      if (report.hastag_suggest_post) {
        report.hastag_suggest_post.map((e) => {
          e.suggest_tag.map((f) => {
            if (f.status === 'approved' && e.suggest_stauts === 'remove_tag') {
              return allTags.push(Object.assign(f));
            }
          });
        });

        const counts = {};
        allTags.forEach((x) => {
          counts[x.hashtag_name] = (counts[x.hashtag_name] || 0) + 1;
        });

        let uniqueArray = [...new Map(allTags.map((v) => [v.hashtag_name, v])).values()];

        RemoveTagApprove = uniqueArray.map((e, i) => {
          let keys = Object.keys(counts);
          keys.forEach((key) => {
            if (e.hashtag_name === key) {
              Object.assign(e, { count: counts[key] });
            }
          });
          return e;
        });
      }
      return RemoveTagApprove.length;
    };

    const solvedRemoveIgnoreTagReq = () => {
      let allTags = [];

      if (report.hastag_suggest_post) {
        report.hastag_suggest_post.map((e) => {
          e.suggest_tag.map((f) => {
            if (f.status === 'rejected' && e.suggest_stauts === 'approved_tag') {
              return allTags.push(Object.assign(f));
            }
          });
        });

        const counts = {};
        allTags.forEach((x) => {
          counts[x.hashtag_name] = (counts[x.hashtag_name] || 0) + 1;
        });

        let uniqueArray = [...new Map(allTags.map((v) => [v.hashtag_name, v])).values()];

        approveTagReject = uniqueArray.map((e, i) => {
          let keys = Object.keys(counts);
          keys.forEach((key) => {
            if (e.hashtag_name === key) {
              Object.assign(e, { count: counts[key] });
            }
          });
          return e;
        });
      }
      return approveTagReject.length;
    };

    const solvedAddIgnoreTagReq = () => {
      let allTags = [];

      if (report.hastag_suggest_post) {
        report.hastag_suggest_post.map((e) => {
          e.suggest_tag.map((f) => {
            if (f.status === 'rejected' && e.suggest_stauts === 'remove_tag') {
              return allTags.push(Object.assign(f));
            }
          });
        });

        const counts = {};
        allTags.forEach((x) => {
          counts[x.hashtag_name] = (counts[x.hashtag_name] || 0) + 1;
        });

        let uniqueArray = [...new Map(allTags.map((v) => [v.hashtag_name, v])).values()];

        RemoveTagReject = uniqueArray.map((e, i) => {
          let keys = Object.keys(counts);
          keys.forEach((key) => {
            if (e.hashtag_name === key) {
              Object.assign(e, { count: counts[key] });
            }
          });
          return e;
        });
      }
      return RemoveTagReject.length;
    };

    const solvedRemoveByAdmin = () => {
      let allTags = [];

      if (report.hastag_suggest_post) {
        report.hastag_suggest_post.map((e) => {
          e.suggest_tag.map((f) => {
            if (f.status === 'remove_by_admin' && e.suggest_stauts === 'approved_tag') {
              return allTags.push(Object.assign(f));
            }
          });
        });

        const counts = {};
        allTags.forEach((x) => {
          counts[x.hashtag_name] = (counts[x.hashtag_name] || 0) + 1;
        });

        let uniqueArray = [...new Map(allTags.map((v) => [v.hashtag_name, v])).values()];

        approveRemoveByAdmin = uniqueArray.map((e, i) => {
          let keys = Object.keys(counts);
          keys.forEach((key) => {
            if (e.hashtag_name === key) {
              Object.assign(e, { count: counts[key] });
            }
          });
          return e;
        });
      }
      return approveRemoveByAdmin.length;
    };

    return (
      solvedRemoveTagReq() +
      solvedAddTagReq() +
      solvedRemoveIgnoreTagReq() +
      solvedAddIgnoreTagReq() +
      solvedRemoveByAdmin()
    );
  };

  const featuredpostTableData = useCallback(() => {
    return hashtagsreports.map((report) => {
      return [
        report.hastag_suggest_post ? report.hastag_suggest_post[0].id : '0',

        <div className="d-inline user-img">
          <Avatar alt="Natacha" className="float-left mr-1" src={report.user.photo_urls.medium} />
          <span>{report.user.user_name}</span>
        </div>,

        report.user.privacy_settings,

        <span className="text_primary">
          {pendingRequestCount(savedRequestCount(report), report)
            ? pendingRequestCount(savedRequestCount(report), report)
            : 0}{' '}
        </span>,

        <span className="text_primary">
          {' '}
          {savedRequestCount(report) ? savedRequestCount(report) : 0}
        </span>,

        solvedRequestCount(report) ? solvedRequestCount(report) : 0,

        <button
          className="action_btn"
          onClick={async () => {
            await getPostDetails(report);
            await setIsShownn(true);
          }}
        >
          edit
        </button>,
      ];
    });
  }, [hashtagsreports, loaded]);

  useEffect(() => {
    let featuredpostRowData = featuredpostTableData(hashtagsreports);
    if (JSON.stringify(featuredpostRowData) !== JSON.stringify(reportsData)) {
      setReportsData(featuredpostRowData);
    }
  }, [featuredpostTableData, hashtagsreports, reportsData, hashtagPostDeatils]);

  return (
    <>
      {''}

      <ReportModel
        {...{ currentReport, isShown, setIsShownn, approveReportedTag, setCurrentReport }}
      />
      <CommonTable
        totalCount={totalCount}
        customPagination={pagination}
        setCustomPagination={setPagination}
        data={reportsData}
        keys={keys}
      ></CommonTable>
    </>
  );
};

export default ReportsTable;
