import React, { useContext, useState, useEffect } from 'react';

import {
  Card,
  CardContent,
  Grid,
  Checkbox,
  Avatar,
  ButtonGroup,
  Button,
  Divider,
  FormLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles,
} from '@material-ui/core';

import { store } from 'store';
import * as UsersAction from 'store/actions/UsersActions';
import * as FeaturedPostAction from 'store/actions/FeaturedPostActions';
import * as commonService from 'utils/CommonService';
import ViewPOstModal from './viewPostModal';

// component
import ApprovalModal from './approvalModal';

import { TextTruncate } from 'utils/TextTruncate';

const User = ({ history }) => {
  const globalState = useContext(store);
  // /featuredPostState
  const { userDetails } = globalState.state.usersState;
  const { pastApprovalReqList } = globalState.state.featuredPostState;
  const { usersDispatch, featuredPostDispatch } = globalState.dispatch;
  const [loaded, setLoaded] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [isShownViewPost, setISShownViewPost] = useState(false);
  const [approvedImage, setApprovedImage] = useState('');

  let id = history.location.pathname.split('/')[2];

  useEffect(() => {
    if (!userDetails.id !== id && !loaded) {
      setLoaded(true);
      UsersAction.getUser({ id, dispatch: usersDispatch });
      UsersAction.getPastApprovalReq(id, {
        dispatch: featuredPostDispatch,
      });
    }
  }, [userDetails, pastApprovalReqList, history, id, loaded, usersDispatch, featuredPostDispatch]);

  const approvePost = (ID, type) => {
    if (type === 'accept') {
      FeaturedPostAction.approvePost(ID, {
        dispatch: featuredPostDispatch,
      }).then((res) => {
        UsersAction.getUser({ id, dispatch: usersDispatch });
      });
    } else {
      FeaturedPostAction.rejectPost(ID, {
        dispatch: featuredPostDispatch,
      }).then((res) => {
        UsersAction.getUser({ id, dispatch: usersDispatch });
      });
    }
  };

  const deleteUser = (user) => {
    if (user)
      UsersAction.deleteUser({ id: user.id, dispatch: usersDispatch }).then((res) => {
        commonService.forSuccess('User deleted successfully!', 'Success');
      });
    commonService.isDialogOpen.onNext(false);
  };
  const handleDelete = (user) => {
    commonService.isDialogOpen.onNext({
      open: true,
      data: {
        message: 'Are you sure?',
      },
      cancelText: 'Cancel',
      confirmText: 'Okay',
      onCancel: () => commonService.isDialogOpen.onNext(false),
      onConfirm: () => deleteUser(user),
    });
  };
  const backPage = () => {
    history.location.state
      ? history.push({
          pathname: history.location.state.path,
          state: {
            page: history.location.state.page,
            limit: history.location.state.limit,
          },
        })
      : history.goBack();
  };

  const useStyles = makeStyles({
    leadTableContainer: {
      boxShadow:
        '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
    },
    selectDropdown: { marginTop: 0 },
  });

  const classes = useStyles();

  return (
    <>
      {showApprovalModal ? (
        <ApprovalModal
          history={history}
          showApprovalModal={showApprovalModal}
          setShowApprovalModal={setShowApprovalModal}
        ></ApprovalModal>
      ) : (
        ''
      )}
      <ViewPOstModal
        isShownViewPost={isShownViewPost}
        setISShownViewPost={setISShownViewPost}
        elem={approvedImage}
      ></ViewPOstModal>

      <div className="row mx-5 mb-2">
        <Button className="btn-default" onClick={backPage} variant="outlined" color="secondary">
          Back
        </Button>
      </div>
      <div className="mx-5">
        <Card>
          {userDetails && (
            <CardContent>
              <>
                <Grid container direction="row" className=" mb-3" spacing={0}>
                  <Avatar
                    className="recipe-img"
                    alt="img"
                    src={userDetails.photo_urls && userDetails.photo_urls.medium}
                  />
                </Grid>
                <Grid container direction="row" className="img-container mb-3" spacing={0}>
                  <Avatar
                    className="recipe-img"
                    alt="img"
                    src={userDetails.header_images && userDetails.header_images.medium}
                  />
                </Grid>
                <Grid container direction="row" className="mt-3" spacing={0}>
                  <Grid item xs={2}>
                    {' '}
                    <FormLabel className="font-color">Name</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    {' '}
                    {userDetails.user_name}
                  </Grid>

                  <Grid item xs={2}>
                    {' '}
                    <FormLabel className="font-color">Role</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    {userDetails.role}
                  </Grid>
                </Grid>

                <Grid container direction="row" className="mt-3" spacing={0}>
                  <Grid item xs={2}>
                    {' '}
                    <FormLabel className="font-color">Bio</FormLabel>
                  </Grid>
                  <Grid item xs={10}>
                    {userDetails.bio}
                  </Grid>
                </Grid>

                <Grid container direction="row" className="mt-3" spacing={0}>
                  <Grid item xs={2}>
                    {' '}
                    <FormLabel className="font-color">Email</FormLabel>
                  </Grid>
                  <Grid item xs={10}>
                    {userDetails.email}
                  </Grid>
                </Grid>

                <Grid container direction="row" className="mt-3" spacing={0}>
                  <Grid item xs={2}>
                    {' '}
                    <FormLabel className="font-color">Privacy Setting</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    {userDetails.privacy_settings}
                  </Grid>
                  <Grid item xs={2}>
                    {' '}
                    <FormLabel className="font-color">Status</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    {userDetails.active_status}
                  </Grid>
                </Grid>

                <Grid container direction="row" className="mt-3" spacing={0}>
                  <Grid item xs={2}>
                    {' '}
                    <FormLabel className="font-color">Show Followers</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    {' '}
                    <Checkbox
                      className="p-0"
                      defaultChecked={userDetails.show_followers}
                      color="primary"
                      disabled
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    {' '}
                    <FormLabel className="font-color">Show Following</FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <Checkbox
                      className="p-0"
                      defaultChecked={userDetails.show_following}
                      color="primary"
                      disabled
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" className="mt-3" spacing={0}>
                  <Grid item xs={2}>
                    {' '}
                    <FormLabel className="font-color">Verified</FormLabel>
                  </Grid>
                  <Grid item xs={10}>
                    <Checkbox
                      className="p-0"
                      defaultChecked={userDetails.verified}
                      color="primary"
                      disabled
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                </Grid>
                {userDetails.approval_status === 'requested' ? (
                  <>
                    <Grid container direction="row" className="mt-3" spacing={0}>
                      <Divider className="w-100 mb-2"></Divider>
                      <h4 className="w-100">Pending Approvals</h4>
                      <Grid item xs={4}>
                        <ButtonGroup
                          variant="text"
                          color="primary"
                          aria-label="text primary button group"
                        >
                          <Button
                            color="primary"
                            onClick={(a) => {
                              approvePost(userDetails.approval.id, 'accept');
                              FeaturedPostAction.userFirstTimeApproved(userDetails.id, {
                                dispatch: featuredPostDispatch,
                              });
                            }}
                          >
                            ACCEPT
                          </Button>
                          <Button
                            color="secondary"
                            onClick={(a) => approvePost(userDetails.approval.id, 'reject')}
                          >
                            REJECT
                          </Button>
                          <Button
                            color="secondary"
                            onClick={
                              (a) => setShowApprovalModal(true)
                              // approvePost(userDetails.approval.id, "reject")
                            }
                          >
                            REQUEST TO REAPPLY
                          </Button>
                        </ButtonGroup>
                        <ButtonGroup
                          variant="text"
                          color="primary"
                          aria-label="text primary button group"
                        >
                          <Button
                            color="secondary"
                            onClick={(a) => {
                              handleDelete(userDetails);
                            }}
                          >
                            DELETE FROM PLATFORM
                          </Button>
                        </ButtonGroup>
                      </Grid>
                      {userDetails.approval.about ? (
                        <Grid item xs={2}>
                          <FormLabel className="font-color">About</FormLabel>
                        </Grid>
                      ) : (
                        ''
                      )}
                      <Grid item xs={6} className="aboot_content_userdetails">
                        {userDetails.approval.about}
                      </Grid>
                      <Grid item xs={2} className="mt-2">
                        <FormLabel className="font-color">Own Content</FormLabel>
                      </Grid>
                      <Grid item xs={2} className="mt-2">
                        <Checkbox
                          className="p-0"
                          defaultChecked={userDetails.approval.own_content}
                          color="primary"
                          disabled
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </Grid>

                      {userDetails.approval.link ? (
                        <Grid item xs={2} className="mt-2">
                          <FormLabel className="font-color">Link</FormLabel>
                        </Grid>
                      ) : (
                        ''
                      )}
                      <Grid item xs={6} className="mt-2">
                        <a
                          href={userDetails.approval.link}
                          className="link_underline"
                          target="_blank"
                        >
                          {userDetails.approval.link}
                        </a>
                      </Grid>

                      <div className="thumb_img_grid">
                        <div className="thumb_img_grid">
                          <div className="feature_img_thumbnail">
                            {userDetails.approval.attachments.map((img, index) => (
                              <div className="feature_item" key={index}>
                                <figure className="feature_fig">
                                  <img src={img.photo_urls.original} alt="thumbnail" />
                                </figure>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Grid>

                    {pastApprovalReqList.length ? (
                      <Grid container direction="row" className="mt-5" spacing={0}>
                        <div className="approval-app-wrapper">
                          <article className="mb-3">
                            <h4>Past applications </h4>
                          </article>
                          <TableContainer
                            component={Paper}
                            className={`mat-elevation-z8 p-3 ${classes.leadTableContainer}`}
                          >
                            <Table aria-label="a dense table" size="small">
                              <TableBody>
                                {pastApprovalReqList.map((elem, i) => (
                                  <TableRow hover role="checkbox" tabIndex={-1} key={'i'}>
                                    <TableCell className="text-left">{elem.id}</TableCell>
                                    <TableCell className="text-left">
                                      <div className="d-inline user-img">
                                        <Avatar
                                          alt="Natacha"
                                          className="float-left mr-1"
                                          src={elem.user.photo_urls.medium}
                                        />
                                        <span>{elem.user.user_name}</span>
                                      </div>
                                    </TableCell>
                                    <TableCell className="text-left">
                                      {TextTruncate({ str: elem.link, limit: 20 })}
                                    </TableCell>
                                    <TableCell
                                      className="text-left aboot_content_userdetails"
                                      style={{ wordBreak: 'break-all' }}
                                    >
                                      {elem.about}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    <Grid container direction="row" className="mt-3" spacing={0}>
                      <Grid item xs={2}>
                        {' '}
                        <FormLabel className="font-color">Approval Status</FormLabel>
                      </Grid>
                      <Grid item xs={10}>
                        {userDetails.approval_status}
                      </Grid>
                    </Grid>
                    {pastApprovalReqList.length ? (
                      <Grid container direction="row" className="mt-5" spacing={0}>
                        <div className="approval-app-wrapper">
                          <article className="mb-3">
                            <h4>Past applications </h4>
                          </article>
                          <TableContainer
                            component={Paper}
                            className={`mat-elevation-z8 p-3 ${classes.leadTableContainer}`}
                          >
                            <Table aria-label="a dense table" size="small">
                              <TableBody>
                                {pastApprovalReqList.map((elem, i) => (
                                  <>
                                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                      <TableCell className="text-left">{elem.id}</TableCell>
                                      <TableCell className="text-left">
                                        <div className="d-inline user-img">
                                          <Avatar
                                            alt="Natacha"
                                            className="float-left mr-1"
                                            src={elem.user.photo_urls.medium}
                                          />
                                          <span>{elem.user.user_name}</span>
                                        </div>
                                      </TableCell>
                                      <TableCell className="text-left aboot_content_userdetails">
                                        <a href={elem.link} target="_blank">
                                          {' '}
                                          {elem.link}{' '}
                                        </a>
                                      </TableCell>
                                      <TableCell className="text-left aboot_content_userdetails">
                                        {elem.about}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      {elem.attachments
                                        ? elem.attachments.map((elem, i) => (
                                            <TableCell
                                              onClick={() => {
                                                setISShownViewPost(true);
                                                setApprovedImage(elem);
                                              }}
                                            >
                                              <img
                                                src={elem.photo_urls.medium}
                                                height="200px"
                                                width="200px"
                                              ></img>
                                            </TableCell>
                                          ))
                                        : ''}
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
            </CardContent>
          )}
        </Card>
      </div>
    </>
  );
};
export default User;
