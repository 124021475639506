import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, ButtonGroup, Avatar } from '@material-ui/core';
import CommonTable from 'components/shared/common-table/CommonTable';
import { TextTruncate } from 'utils/TextTruncate';
import { store } from 'store';
import * as FeaturedPostAction from 'store/actions/FeaturedPostActions';
import '../../../../node_modules/bootstrap/dist/js/bootstrap';

const FeaturedPostTable = ({ totalCount }) => {
  const [featuredpostData, setFeaturedPostData] = useState([]);
  const keys = ['id', 'Username', 'Link', 'About', 'status'];

  const history = useHistory();
  const globalState = useContext(store);
  const { featuredPostDispatch } = globalState.dispatch;
  const { featuredPostListApproved, status, count2, isLoaded } =
    globalState.state.featuredPostState;
  const [rowData, setRowData] = useState([]);

  const [pagination, setDefaultPagination] = useState({
    page: history.location.state && history.location.state.page ? history.location.state.page : 1,
    limit:
      history.location.state && history.location.state.limit ? history.location.state.limit : 10,
    approvedStatus: 'accepted',
    // approvedStatus: "requested"
  });
  const [filterParams] = useState({});
  const [loaded, setLoaded] = useState(false);

  const featuredpostTableData = useCallback(() => {
    return featuredPostListApproved.map((post) => {
      return [
        post.id,
        <div className="d-inline user-img">
          <Avatar
            alt="Natacha"
            className="float-left mr-1"
            src={post.photo_urls && post.photo_urls.medium}
          />
          <Link
            to={{
              pathname: `/users/${post.id}/details`,
              state: {
                page: pagination.page,
                limit: pagination.limit,
                path: history.location.pathname,
              },
            }}
          >
            {post.user_name}
          </Link>
        </div>,
        <a href={post.approval.link} target="_blank">
          {TextTruncate({ str: post.approval.link, limit: 20 })}
        </a>,

        TextTruncate({ str: post.approval.about, limit: 20 }),
        post.approval.status,
      ];
    });
  }, [featuredPostListApproved, pagination, history]);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      if (!isLoaded)
        FeaturedPostAction.getFeaturedPostsApproved({
          body: { ...pagination },
          dispatch: featuredPostDispatch,
        });
    }
  }, [
    rowData,
    featuredPostListApproved,
    status,
    featuredPostDispatch,
    pagination,
    loaded,
    isLoaded,
  ]);

  useEffect(() => {
    if (rowData.length !== featuredPostListApproved.length || status !== 'init') {
      const rows = featuredPostListApproved;
      setRowData(rows);
    }
  }, [rowData, status, featuredPostListApproved]);

  const setPagination = (params) => {
    setDefaultPagination({ ...pagination, ...params });
    FeaturedPostAction.getFeaturedPostsApproved({
      body: { ...pagination, ...params, ...filterParams },
      dispatch: featuredPostDispatch,
    });
  };

  useEffect(() => {
    let featuredpostRowData = featuredpostTableData(featuredPostListApproved);
    if (JSON.stringify(featuredpostRowData) !== JSON.stringify(featuredpostData)) {
      setFeaturedPostData(featuredpostRowData);
    }
  }, [featuredpostTableData, featuredPostListApproved, featuredpostData]);
  return (
    <CommonTable
      totalCount={count2}
      customPagination={pagination}
      setCustomPagination={setPagination}
      data={featuredpostData}
      keys={keys}
    ></CommonTable>
  );
};

export default FeaturedPostTable;
