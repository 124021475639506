// user api
import * as postApi from 'api/postAPI';
import { PostActionType } from 'store/constants/PostActionType';

export const getPost = async ({ id, dispatch }) =>
  dispatch({
    type: PostActionType.GET_POST_FULFILLED,
    payload: await postApi.getPost(id),
  });

export const deletePost = async ({ id, dispatch }) =>
  dispatch({
    type: PostActionType.DELETE_POST_FULFILLED,
    payload: await postApi.deletePost(id),
    params: { id },
  });
export const setPost = async ({ dispatch }) =>
  dispatch({
    type: PostActionType.GET_POST_FULFILLED,
    payload: {},
  });
