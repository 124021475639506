// user api
import * as usersApi from 'api/UsersAPI';

import * as approvalAPI from '../../api/ApprovalAPI';

import { UsersActionType } from 'store/constants/UsersActionType';

export const getUsers = async ({ body, dispatch }) =>
  dispatch({
    type: UsersActionType.GET_USERS_FULFILLED,
    payload: await usersApi.getUsers(body),
  });

export const getExportedUsersData = async ({ body, dispatch }) => {
  const res = await usersApi.getUsers(body);
  dispatch({
    type: UsersActionType.GET_USERS_DATA_FULFILLED,
    payload: res,
  });
  return res;
};

export const getUser = async ({ id, dispatch }) =>
  dispatch({
    type: UsersActionType.GET_USER_FULFILLED,
    payload: await usersApi.getUser(id),
  });

export const approvedUser = async ({ body, id, dispatch }) =>
  dispatch({
    type: UsersActionType.APPROVED_USER_FULFILLED,
    payload: await usersApi.approvedUser(body, id),
    params: id,
  });
export const deactivateUser = async ({ id, dispatch }) =>
  dispatch({
    type: UsersActionType.DEACTIVATE_USER_FULFILLED,
    payload: await usersApi.deactivateUser(id),
    params: id,
  });

export const filterUsers = async ({ body, dispatch }) =>
  dispatch({
    type: UsersActionType.FILTER_USERS_FULFILLED,
    payload: await usersApi.filterUsers(body),
  });

export const deleteUser = async ({ id, dispatch }) =>
  dispatch({
    type: UsersActionType.DELETE_USERS_FULFILLED,
    payload: await usersApi.deleteUser(id),
    params: { id },
  });

export const reApplyApproval = async (body, approvalId, { dispatch }) => {
  dispatch({
    type: UsersActionType.REAPPLY_APPROVED_USER_FULFILLED,
    payload: await approvalAPI.reApplyApproval(body, approvalId),
  });
};

export const getPastApprovalReq = async (userId, { dispatch }) => {
  dispatch({
    type: UsersActionType.PAST_APPROVAL_REQUESTS_FULFILLED,
    payload: await approvalAPI.getPastApprovalReq(userId),
  });
};
