// Hashtag api
import * as hashtagAPI from '../../api/HashtagAPI';

import { HashtagActionType } from '../constants/HashtagActionType';

export const getHashtags = async ({ body, dispatch }) =>
  dispatch({
    type: HashtagActionType.GET_HASHTAG_LIST,
    payload: await hashtagAPI.getHashtags(body),
  });

export const createHashtag = async ({ body, dispatch }) =>
  dispatch({
    type: HashtagActionType.ADD_HASHTAG,
    payload: await hashtagAPI.createHashtags(body),
  });

export const updateHashtag = async ({ body, id, dispatch }) =>
  dispatch({
    type: HashtagActionType.UPDATE_HASHTAG,
    payload: await hashtagAPI.updateHashtags(body, id),
  });

export const filterHashtags = async ({ body, dispatch }) =>
  dispatch({
    type: HashtagActionType.FILTER_HASHTAGS_FULFILLED,
    payload: await hashtagAPI.filterHashtags(body),
  });

export const deleteHashtag = async ({ id, dispatch }) =>
  dispatch({
    type: HashtagActionType.DELETE_HASHTAGS_FULFILLED,
    payload: await hashtagAPI.deleteHashtag(id),
    params: { id },
  });
