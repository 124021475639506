import axios from 'axios';
import { API_VIRSION } from 'config';

export const getHashtags = (params) =>
  axios.get(`${API_VIRSION}/admin/post/hashtag/suggestions`, { params });

export const approveHashtag = (id, body) =>
  axios.put(`${API_VIRSION}/admin/post/hashtag/${id}/approve`, body);

export const rejectHashtag = (id, body) =>
  axios.put(`${API_VIRSION}/admin/post/hashtag/${id}/reject`, body);

export const filterSuggestedHashtags = (params) =>
  axios.get(`${API_VIRSION}/admin/post/hashtag/suggestions`, { params });

export const getPostDetails = (id) => axios.get(`${API_VIRSION}/admin/post/hashtag/${id}`);

export const addTagsInPost = (id, body) => {
  return axios.put(`${API_VIRSION}/admin/post/add_hashtag/${id}`, body);
};

export const removeTagsInPost = (id, body) => {
  return axios.put(`${API_VIRSION}/admin/post/remove_hashtag/${id}`, body);
};
