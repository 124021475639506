import React from 'react';
import { NavLink } from 'react-router-dom';

const SideMenu = ({ icon, title, link, exact }) => {
  const isActive = (path, match, location) => {
    return !!(match || (path === '/' && location.pathname.includes('store')));
  };

  return (
    <li>
      <NavLink
        className="app-menu__item"
        to={link}
        activeClassName="active"
        exact={exact}
        isActive={isActive.bind(this, link)}
      >
        <i className={`fas ${icon} sidebar-icon pr-1`}></i>
        <span className="app_menu__label">{title}</span>
      </NavLink>
    </li>
  );
};

export default SideMenu;
