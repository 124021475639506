import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
// import page layout and container for private and public page;
import { PublicRouterLayout, PrivateRouterLayout } from './routerLayout';

import { store } from 'store';
export const PrivateRoute = (props) => {
  const globalState = useContext(store);
  const { userAuthState } = globalState.state;

  const { component: Component, ...restProps } = props;

  if (!Component) return null;

  return (
    <Route
      {...restProps}
      render={(routeRenderProps) =>
        userAuthState.authToken ? (
          <PrivateRouterLayout>
            <Component {...routeRenderProps} />
          </PrivateRouterLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeRenderProps.location },
            }}
          />
        )
      }
    />
  );
};

export const PublicRoute = (props) => {
  const globalState = useContext(store);
  const { userAuthState } = globalState.state;

  const { component: Component, ...restProps } = props;

  if (!Component) return null;
  return (
    <Route
      {...restProps}
      render={(routeRenderProps) =>
        !userAuthState.authToken ? (
          <PublicRouterLayout>
            <Component {...routeRenderProps} />
          </PublicRouterLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: routeRenderProps.location },
            }}
          />
        )
      }
    />
  );
};
