import axios from 'axios';
import { API_VIRSION } from 'config';
// Hashtag api
export const getHashtags = (params) => axios.get(`${API_VIRSION}/admin/hashtag`, { params });

export const createHashtags = (body) => axios.post(`${API_VIRSION}/admin/hashtag`, body);

export const updateHashtags = (body, id) => axios.put(`${API_VIRSION}/admin/hashtag/${id}`, body);

export const filterHashtags = (params) => axios.get(`${API_VIRSION}/admin/hashtag`, { params });

export const deleteHashtag = (id) => axios.delete(`${API_VIRSION}/admin/hashtag/${id}`);
