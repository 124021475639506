import React, { useContext, useEffect, useState, useCallback } from 'react';

import * as ReportedPostsActions from 'store/actions/ReportedPostsAction';
import { store } from 'store';

import ReportsTable from './ReportsTable';

import Filter from '../shared/ui-components/filter/filter';

import './report.css';
const ReportedPosts = () => {
  const [pagination, setPagination] = useState({ page: 1, limit: 10, orderBy: 'DESC' });
  const globalState = useContext(store);
  const { reportDispatch } = globalState.dispatch;
  const { reports, count, status } = globalState.state.reportState;
  const [filterParams, setFilterParams] = useState({});

  useEffect(() => {
    if (status !== 'updated_reports') {
      ReportedPostsActions.getReportedPosts({
        params: pagination,
        dispatch: reportDispatch,
      });
    }
  }, [reportDispatch]);

  const deleteReportedPost = async (body, id) => {
    await ReportedPostsActions.deleteReportedPost({
      body,
      id,
      dispatch: reportDispatch,
    });

    ReportedPostsActions.getReportedPosts({
      params: pagination,
      dispatch: reportDispatch,
    });
  };

  const ignoreReportedPost = async (status, id) => {
    await ReportedPostsActions.deleteReportedPost({
      body: { report: { status } },
      id,
      dispatch: reportDispatch,
    });
    ReportedPostsActions.getReportedPosts({
      params: pagination,
      dispatch: reportDispatch,
    });
  };

  const revertReportedPost = async (id) => {
    await ReportedPostsActions.revertReportedPost({ id, dispatch: reportDispatch });
    ReportedPostsActions.getReportedPosts({
      params: pagination,
      dispatch: reportDispatch,
    });
  };
  const handlePagination = (params) => {
    setPagination({ ...pagination, ...params });
    ReportedPostsActions.getReportedPosts({
      params: { ...pagination, ...params },
      dispatch: reportDispatch,
    });
  };

  const handleFilter = (e, filterForm) => {
    e.preventDefault();
    setPagination({ ...pagination, page: 1 });
    setFilterParams(filterForm);
    ReportedPostsActions.filterReportedPosts({
      params: { ...filterForm },
      dispatch: reportDispatch,
    });
  };

  const resetLeadFilterForm = (data) => {
    setFilterParams(data);
    setPagination({ page: 1, limit: 10 });
    ReportedPostsActions.getReportedPosts({
      params: pagination,
      dispatch: reportDispatch,
    });
  };

  return (
    <div className="report-page-container">
      <Filter
        handleFilter={handleFilter}
        resetLeadFilterForm={resetLeadFilterForm}
        str="reportPost"
      ></Filter>
      <ReportsTable
        {...{
          reports,
          deleteReportedPost,
          revertReportedPost,
          ignoreReportedPost,
          pagination,
          setPagination: handlePagination,
          totalCount: count,
        }}
      />
    </div>
  );
};

export default ReportedPosts;
