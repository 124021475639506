import toastr from 'toastr';
import { BehaviorSubject } from 'rx';
import states from 'us-state-codes';
import { format } from 'date-fns';

toastr.options = {
  positionClass: 'toast-top-right',
  showDuration: '300',
  timeOut: 3000,
  closeButton: true,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};
// for global loader service
export const isLoading = new BehaviorSubject(false);

export const forSuccess = (message, title) => toastr.success(message, title);

export const forError = (message, title) => toastr.error(message, title);

export const forWarning = (message, title) => toastr.warning(message, title);

export const isDialogOpen = new BehaviorSubject(false);

export const getFullName = (obj) => {
  if (obj) return obj.first_name + ' ' + obj.last_name;
  else return '';
};

export const currencyFormat = (number) => {
  let fixedTwo = parseFloat(number.toFixed(2));
  return new Intl.NumberFormat('en-IN').format(fixedTwo);
};
export const getStateName = (state) => {
  let code = states.getStateCodeByStateName(state);
  return code ? code : state;
};

export const formatDate = (string) => {
  return format(
    new Date(string.substring(0, 4), string.substring(4, 6) - 1, string.substring(6, 8)),
    'MMM. d, yyyy',
  );
};

export const transformToDate = (string) => {
  return new Date(string.substring(0, 4), string.substring(4, 6) - 1, string.substring(6, 8));
};
