import { Divider } from '@material-ui/core';
import React, { useContext } from 'react';

import './index.scss';
import {
  Card,
  CardContent,
  //FormControl,
  //InputLabel,
  FormGroup,
} from '@material-ui/core';
//custom form component
import {
  //SelectInput,
  FormInput,
  FormButton,
} from 'components/shared/ui-components/form-components';
import { store } from 'store';

import * as commonService from 'utils/CommonService';
import * as postAction from 'store/actions/PostAction';

const Post = () => {
  const globalState = useContext(store);
  const { post } = globalState.state.postState;
  const { postDispatch } = globalState.dispatch;
  const [postURL, setPostURL] = React.useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = postURL.split('/');
    let id;
    if (postData.length) id = postData.reverse()[0];
    else id = postURL;
    postAction.getPost({ id, dispatch: postDispatch });
  };

  const handleDelete = () => {
    const postData = postURL.split('/');
    let id;
    if (postData.length) id = postData.reverse()[0];
    else id = postURL;
    commonService.isDialogOpen.onNext({
      open: true,
      data: {
        message: 'Are you sure?',
      },
      cancelText: 'Cancel',
      confirmText: 'Okay',
      onCancel: () => commonService.isDialogOpen.onNext(false),
      onConfirm: () => deletePost(id),
    });
  };

  const deletePost = (id) => {
    if (id)
      postAction.deletePost({ id, dispatch: postDispatch }).then((res) => {
        commonService.forSuccess('Post deleted successfully!', 'Success');
      });
    postAction.setPost({ dispatch: postDispatch });
    setPostURL('');
    commonService.isDialogOpen.onNext(false);
  };
  return (
    <>
      <div className="row mb-5">
        <div className="col-12">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-10">
                    <div className="row">
                      <FormGroup className="col-md-6 col-sm-6">
                        <FormInput
                          label="Post"
                          name="post"
                          value={postURL}
                          onChange={(e) => setPostURL(e.target.value)}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="row pt-2">
                      <FormButton
                        variant="contained"
                        color="secondary"
                        className="btn btn-primary mr-2"
                        type="submit"
                      >
                        Get Post
                      </FormButton>
                      <FormButton
                        variant="contained"
                        color="secondary"
                        className="btn btn-primary "
                        type="button"
                        onClick={handleDelete}
                      >
                        Delete
                      </FormButton>
                    </div>
                  </div>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
      {post ? (
        <div id="wrap" className="lps_xl_view">
          <div className="lps_container main_feed_cont bg_grayCCC pb-5 w-100">
            <div className="lps_list lps_dsk_list postBox">
              <div className="lps_inner_wrp_media ">
                <div className="lps_media mProfile">
                  <figure className="lps_fig lps_fig_circle">
                    <img src={post.user.photo_urls.medium} alt="User" />
                  </figure>
                  <div className="lps_media_body">
                    <div className="lps_media_body">
                      <p className="moreDesktop desc-break">
                        <span className="text_primary">
                          <a>{post.user.user_name}</a>
                        </span>
                        {'  '}
                        {post.attachments.length ? post.description : ''}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {post.attachments.length ? (
                <div className="post_img_block lps_pink_bg lps_widgets_wrp model_border textContent hightAuto">
                  <div className="lps_sm_shape"></div>
                  <a>
                    <figure className="feed_galary lps_flx_vm_jc lps_f_vm lps_bg_prty pt-0">
                      <img src={post.attachments[0].photo_urls.webp} alt="Add Image" />
                    </figure>
                  </a>
                </div>
              ) : (
                <div className="lps_inner_wrp post_img_block lps_pink_border lps_neon_pink_border lps_widgets_wrp lps_mt_50 textContent">
                  <div className="lps_sm_shape lps_sm_shape1"></div>
                  <div className="lps_inner_cont lps_pos_rltv">
                    <article className="lps_art">
                      <a id="trigger_text_feed1">
                        <p className="reposted_text_feed">{post.description}</p>
                      </a>
                    </article>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
export default Post;
