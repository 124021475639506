import React, { useState, useEffect } from 'react';

import originalMoment from 'moment';
import { extendMoment } from 'moment-range';

import { queryReport } from '../../../../apiBody/queryReport';
import Card from '../../Card';

import { VIEW_ID } from 'config';

const moment = extendMoment(originalMoment);

const ExploreReport = () => {
  const [reportData, setReportData] = useState();
  let count = 0;
  const today = moment();
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, 'days'), today.clone()),
  );

  let { start, end } = value;
  start = start.format('YYYY-MM-DD');
  end = end.format('YYYY-MM-DD');

  const displayResults = (response) => {
    setReportData([{}]);
    const queryResult = response.result.reports[0].data.rows;
    let newReportData = [];

    queryResult.forEach((row) => {
      if (row.dimensions[1] === '/Explore') {
        count = count + parseInt(row.metrics[0].values[0]);
      }
    });

    newReportData.push([{ count: `${count}` }]);
    setReportData(newReportData);
  };

  useEffect(() => {
    const request = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:totalEvents',
      dimensions: ['ga:eventCategory', 'ga:eventAction', 'ga:date'],
    };

    queryReport(request).then((resp) => displayResults(resp));
  }, [value]);

  return (
    <>
      {reportData && reportData.length > 0 ? (
        reportData.map((data, i) => (
          <Card
            pageTitle={'Explore Page Visitor'}
            isRegistration={false}
            key={i}
            data={data}
            value={value}
            setValue={setValue}
          ></Card>
        ))
      ) : (
        <Card
          pageTitle={'Explore Page Visitor'}
          isRegistration={false}
          data={reportData}
          value={value}
          setValue={setValue}
        ></Card>
      )}
    </>
  );
};

export default ExploreReport;
