import { UserAuthActionType } from 'store/constants/AuthActionType';

let localUser = localStorage['user'] ? JSON.parse(localStorage['user']) : null;

const userAuthInitialState = {
  currentUser: localUser,
  authToken: localStorage['authToken'] || null,
};

const AuthReducer = (state = userAuthInitialState, action = {}) => {
  switch (action.type) {
    case UserAuthActionType.GET_AUTH_TOKEN_FULFILLED:
      const { user, token } = action.payload;
      localStorage['user'] = JSON.stringify(user);
      localStorage['authToken'] = token;
      return {
        ...state,
        authToken: token,
        currentUser: user,
      };
    case UserAuthActionType.REMOVE_AUTH_TOKEN:
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      return {
        ...state,
        authToken: null,
        currentUser: {},
      };
    default:
      return state;
  }
};

export { AuthReducer, userAuthInitialState };
