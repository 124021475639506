import { UsersActionType } from 'store/constants/UsersActionType';

const usersInitialState = {
  userList: [],
  userDetails: {},
  status: 'init',
  count: 1,
};

const UsersReducer = (state = usersInitialState, action = {}) => {
  let users = [];
  let index;
  switch (action.type) {
    case UsersActionType.GET_USERS_FULFILLED:
      return {
        ...state,
        userList: action.payload.users,
        count: action.payload.Count,
        status: 'added',
      };
    case UsersActionType.GET_USER:
      return {
        ...state,
        userDetails: {},
      };

    case UsersActionType.GET_USER_FULFILLED:
      return {
        ...state,
        userDetails: action.payload.user,
        status: 'init',
      };
    case UsersActionType.APPROVED_USER_FULFILLED:
      users = [...state.userList];
      index = users.findIndex((e) => e.id === action.payload.user.id);
      users[index]['account_approved'] = action.payload.user.account_approved;
      return {
        ...state,
        userList: users,
        status: 'init',
      };
    case UsersActionType.REAPPLY_APPROVED_USER_FULFILLED:
      users = [...state.userList];
      index = users.findIndex((e) => e.id === action.payload.user.id);
      users[index]['account_approved'] = action.payload.user.account_approved;
      return {
        ...state,
        userList: users,
        status: 'init',
      };
    case UsersActionType.FILTER_USERS_FULFILLED:
      return {
        ...state,
        userList: action.payload.users,
        status: 'updated_users',
        count: action.payload.Count,
      };

    case UsersActionType.DELETE_USERS_FULFILLED:
      users = [...state.userList];
      users.splice(
        users.findIndex((user) => user.id === action.params.id),
        1,
      );
      return {
        ...state,
        userList: users,
        status: 'deleted',
      };
    default:
      return state;
  }
};

export { UsersReducer, usersInitialState };
