import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import * as commonService from "utils/CommonService";
// import * as actions from "store/actions/FeaturedAdActions";

export default ({ formType, ...props }) => {
  const defaultForm = {
    title: '',
    description: '',
    image_url: '',
    external_link: '',
  };
  const [form, setForm] = useState(defaultForm);
  const [errors, setError] = useState({});
  const [files, setFile] = useState([]);

  const handleFile = (e) => {
    let file = e.target.files[0];
    let url = URL.createObjectURL(file);
    let newFiles = [{ src: url, file }];
    setFile(newFiles);
  };

  const handleChange = ({ target }) => {
    if (target.value.trim() || !target.value || typeof target.value !== 'string')
      setForm({ ...form, [target.name]: target.value });
    setError({ ...errors, [target.name]: '' });
  };

  return (
    <div className="pr_dash_right_boxes_main float_left">
      <div className="row">
        <div className="col-md-12">
          <div className="st_dash_n_harmony_form_box">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group  vs_cat_upload_input_box float_left">
                    <label>
                      Title
                      <span
                        aria-hidden="true"
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
                      >
                        &thinsp;*
                      </span>
                    </label>
                    <input
                      autoComplete="none"
                      className="form-control"
                      type="text"
                      placeholder="Type here"
                      name="title"
                      value={form.title}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group  vs_cat_upload_input_box float_left">
                    <label>
                      Description
                      <span
                        aria-hidden="true"
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
                      >
                        &thinsp;*
                      </span>
                    </label>
                    <textarea
                      rows="6"
                      autoComplete="none"
                      className="form-control"
                      type="text"
                      placeholder="Type here"
                      name="description"
                      value={form.description}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group  vs_cat_upload_input_box float_left">
                    <label>
                      Image
                      <span
                        aria-hidden="true"
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
                      >
                        &thinsp;*
                      </span>
                    </label>
                    <input
                      type="file"
                      accepts="image/*"
                      placeholder="Type here"
                      className="mb-2 form-control"
                      name="image_url"
                      onChange={handleFile}
                    />
                    {files.length ? (
                      <img src={files[0].src} alt="Add" height="150" />
                    ) : (
                      <img src={require('assets/images/icon/user.jpg')} alt="Add" height="150" />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group  vs_cat_upload_input_box float_left">
                    <label>
                      External link
                      <span
                        aria-hidden="true"
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
                      >
                        &thinsp;*
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="mb-2 form-control"
                      name="external_link"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="vs_cat_upload_btn_box float-right">
                    <Link to="#" className="st_btn btn-default btn">
                      CANCEL
                    </Link>
                    <button type="submit" className="st_btn btn-primary btn submit-btn">
                      {formType === 'update' ? 'Update Featured Ad' : 'Add Featured Ad'}{' '}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
