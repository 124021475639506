// public routes
import Login from '../containers/login';

//private routes
import Users from 'containers/users';
import UserDetail from 'containers/users/userDetails';
import Hashtag from 'containers/hashtag';
import FeaturedPost from 'containers/FeaturedPost';
import SuggestedHashtag from 'containers/suggestedHashtag';
import AddFeaturedAD from 'containers/FeaturedAd/New';
import EditFeaturedAD from 'containers/FeaturedAd/Edit';
import FeaturedAD from 'containers/FeaturedAd';
import CouponCode from 'containers/couponCode';
import Moderation from 'containers/Moderation';
import ReportedPosts from 'containers/reported-posts';
import AnalyticDashboard from 'containers/analyticDashboard';
import ReportedTags from 'containers/reported-hashtags';

export const publicRoutes = [
  {
    key: 'login',
    path: '/login',
    component: Login,
    exact: true,
  },
];

export const privateRoutes = [
  {
    key: 'hashtag',
    path: '/',
    component: Hashtag,
    exact: true,
  },

  {
    key: 'user',
    path: '/users',
    component: Users,
    exact: true,
  },

  {
    key: 'user detail',
    path: '/users/:id/details',
    component: UserDetail,
    exact: true,
  },

  {
    key: 'Featured Post',
    path: '/action-items',
    component: FeaturedPost,
    exact: true,
  },
  {
    key: 'Suggested Hashtag',
    path: '/suggested-hashtag',
    component: SuggestedHashtag,
    exact: true,
  },
  {
    key: 'New Featured-Ad',
    path: '/featured-ad/new',
    component: AddFeaturedAD,
    exact: true,
  },
  {
    key: 'Edit Featured-Ad',
    path: '/featured-ad/:id/edit',
    component: EditFeaturedAD,
    exact: true,
  },
  {
    key: 'Featured-Ad',
    path: '/featured-ads',
    component: FeaturedAD,
    exact: true,
  },
  {
    key: 'Coupon Code',
    path: '/coupon-codes',
    component: CouponCode,
    exact: true,
  },
  {
    key: 'Moderation',
    path: '/moderation',
    component: Moderation,
    exact: true,
  },
  {
    key: 'ReportedPost',
    path: '/reported-posts',
    component: ReportedPosts,
    exact: true,
  },
  {
    key: 'Analytics',
    path: '/analytics',
    component: AnalyticDashboard,
    exact: true,
  },
  {
    key: 'ReportedHagTags',
    path: '/edit-post-tags',
    component: ReportedTags,
    exact: true,
  },
];
