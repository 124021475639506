import React, { useState } from 'react';
import * as commonService from 'utils/CommonService.js';

const ReportModel = ({
  setCurrentReport,
  currentReport,
  deleteReportedPost,
  isShown,
  setIsShown,
}) => {
  const [delete_reason, setReason] = useState('');
  const [report_reason, setDefaultReason] = useState('');
  const [otherOption, setOtherOption] = useState(false);
  const [otherOptionreason, setOtherOptionreason] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (delete_reason || (otherOptionreason && otherOption)) {
      const body = { report: { report_reason, delete_reason, status: 'deleted_initial' } };

      for (const elem in body.report) if (!body.report[elem]) delete body.report[elem];

      await setIsShown(false);
      await setCurrentReport({});
      await deleteReportedPost(body, currentReport.id);
      await setDefaultReason('');
      await setReason();
    } else if (otherOption) {
      commonService.forError('Please enter reason.');
    } else commonService.forError('Please select any reason.');
  };

  return (
    <div
      className={`modal fade lps-modal lps-modal-approvel1 ${isShown ? 'show' : ''}`}
      id="deleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <form className="post-form" onSubmit={handleSubmit}>
              <article>
                <h3 className="modal-title">
                  Notify{' '}
                  <span className="text-capitalize">
                    {currentReport && currentReport.user ? currentReport.user.user_name : ''}
                  </span>{' '}
                  and Reporters
                </h3>
                <p className="modal-para">
                  Your post violated our community guidelines so we removed it. The post
                </p>
              </article>
              <ul className="post-list">
                <li>
                  <input
                    type="radio"
                    name="reason"
                    value={delete_reason}
                    onClick={() =>
                      setReason('contained explicit (non-expressive) genitalia or nudity.')
                    }
                  />
                  contained explicit (non-expressive) genitalia or nudity.
                </li>
                <li>
                  <input
                    type="radio"
                    name="reason"
                    value={delete_reason}
                    onClick={() =>
                      setReason(
                        'contained hate speech or content that harms/limits the free expression of others.',
                      )
                    }
                  />
                  contained hate speech or content that harms/limits the free expression of others.
                </li>
                <li>
                  <input
                    type="radio"
                    name="reason"
                    value={delete_reason}
                    onChange={() =>
                      setReason('did not propercy credit the models or its original creator.')
                    }
                  />
                  did not properly credit the models or its original creator.
                </li>
                <li>
                  <input
                    type="radio"
                    name="reason"
                    value={otherOption}
                    onClick={() => setOtherOption(!otherOption)}
                  />
                  other (type in reason manually)
                  <div className="textareaCountWrapper">
                    <input
                      type="text"
                      className="form-control"
                      name="reason"
                      value={otherOptionreason}
                      onChange={(e) => {
                        return setOtherOptionreason(e.target.value), setReason(e.target.value);
                      }}
                      placeholder="type reason"
                    />
                    <span className="textRange">{otherOptionreason.length}/200</span>
                  </div>
                </li>
              </ul>
              <div className="form-group">
                <textarea
                  value={report_reason}
                  onChange={(e) => setDefaultReason(e.target.value)}
                  className="form-control textarea-input"
                  rows="4"
                  placeholder="type a custom message to add to the notification (optional,
                  visible to the post author only)"
                ></textarea>
                <span></span>
              </div>
              <div className="lps-btn-wrapper">
                <button type="submit" className="btn btn-primary">
                  delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={async () => {
                    await setDefaultReason('');
                    await setReason();
                    await setIsShown(false);
                  }}
                >
                  cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportModel;
