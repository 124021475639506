// Hashtag api
import * as reportedHagtagAPI from '../../api/reportedHagtagAPI'; // Api

import { ReportedHastagActionType } from '../constants/ReportedHastagActionType';

export const getSuggestedHashtags = async ({ params, dispatch }) => {
  return dispatch({
    type: ReportedHastagActionType.GET_REPORTED_HASHTAGS,
    payload: await reportedHagtagAPI.getHashtags(params), // Api
  });
};

export const approveHashtag = async ({ id, dispatch, body }) => {
  return dispatch({
    type: ReportedHastagActionType.REMOVE_REPORTED_HASHTAGS,
    payload: await reportedHagtagAPI.approveHashtag(id, body), // Api
  });
};

export const rejectHashtag = async ({ id, dispatch, body }) => {
  dispatch({
    type: ReportedHastagActionType.REJECT_REPORTED_HASHTAGS,
    payload: await reportedHagtagAPI.rejectHashtag(id, body), // Api
  });
};

export const revertHashtag = async ({ id, dispatch }) =>
  dispatch({
    type: ReportedHastagActionType.REJECT_REPORTED_HASHTAGS,
    payload: await reportedHagtagAPI.rejectHashtag(id), // Api
  });

export const getPostDetails = async ({ id, dispatch }) => {
  dispatch({
    type: ReportedHastagActionType.GET_POST_DETAILS,
    payload: await reportedHagtagAPI.getPostDetails(id), // Api
  });
};

// without reporting to user
export const addTagsInPost = async ({ id, body, dispatch }) => {
  dispatch({
    type: ReportedHastagActionType.ADD_HASHTAGS_POST,
    payload: await reportedHagtagAPI.addTagsInPost(id, body), // Api
  });
};

// without reporting to user
export const removeTagsInPost = async ({ id, body, dispatch }) => {
  dispatch({
    type: ReportedHastagActionType.REMOVE_HASHTAGS_POST,
    payload: await reportedHagtagAPI.removeTagsInPost(id, body), // Api
  });
};

export const filterSuggestedHashtags = async ({ params, dispatch }) => {
  dispatch({
    type: ReportedHastagActionType.FILTER_REPORTED_HASHTAGS_FULFILLED,
    payload: await reportedHagtagAPI.filterSuggestedHashtags(params),
  });
};

export const getExportedUsersData = async ({ params, dispatch }) => {
  const res = await reportedHagtagAPI.getHashtags(params);
  dispatch({
    type: ReportedHastagActionType.GET_REPORTED_DATA,
    payload: res,
  });
  return res;
};
