import React, { useState, useEffect } from 'react';

import originalMoment from 'moment';
import { extendMoment } from 'moment-range';

import { queryReport } from '../../../apiBody/queryReport';
import Card from '../Card';

import { VIEW_ID, RetentionRateLink } from 'config';

const moment = extendMoment(originalMoment);

const Report = () => {
  const [reportData, setReportData] = useState();
  let count = 0;
  const today = moment();
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, 'days'), today.clone()),
  );

  let { start, end } = value;
  start = start.format('YYYY-MM-DD');
  end = end.format('YYYY-MM-DD');

  const displayResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;
    let newReportData = [];

    queryResult.forEach((row) => {
      if (row.dimensions[0] === 'Returning Visitor') {
        count = count + parseInt(row.metrics[0].values[0]);
      }
    });

    newReportData.push([{ count: `${count}` }]);
    setReportData(newReportData);
  };

  useEffect(() => {
    setReportData([]);
    const request = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:users',
      dimensions: ['ga:userType', 'ga:date'],
    };
    queryReport(request).then((resp) => displayResults(resp));
  }, [value]);

  return (
    <>
      <div className="col-sm-3">
        <div className="card card-status">
          <div className="card-body">
            <h4 style={{ paddingTop: '15px' }}>For Retention Rate</h4>
            <h6 className="card-status-subtitle text-muted">
              <a href={RetentionRateLink()} target="blank">
                click here
              </a>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
