import axios from 'axios';
import { API_VIRSION } from 'config';
// FeaturedAd api

export const getFeaturedAd = (body) => {
  return { data: { featuredAd: [] } };
};

export const createFeaturedAd = (body) => axios.patch(`${API_VIRSION}/presign_url`, body);

export const updateFeaturedAd = (body) => axios.patch(`${API_VIRSION}/presign_url`, body);

export const fetchUploadUrl = (body) => axios.patch(`${API_VIRSION}/presign_url`, body);
