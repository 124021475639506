import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import { Line } from 'react-chartjs-2';

import originalMoment from 'moment';
import { extendMoment } from 'moment-range';

import { VIEW_ID } from 'config';

import { queryReport } from '../../apiBody/queryReport';

const AverageDailySessions = () => {
  const moment = extendMoment(originalMoment);
  const data = {
    labels: [],
    datasets: [
      {
        label: 'Sessions',
        data: [],
        backgroundColor: 'rgb(207, 226, 255)',
        borderColor: 'rgb(34, 39, 74)',
        borderWidth: 1,
      },
      {
        label: 'Active users',
        data: [],
        backgroundColor: 'rgb(199, 205, 212)',
        borderColor: 'rgb(13, 33, 11)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const [Data, setData] = useState({ data, options });
  const [isSession, setIsSession] = useState(false);
  const [isActiveUser, setActiveUser] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const today = moment();
  const [value] = useState(moment.range(today.clone().subtract(7, 'days'), today.clone()));

  let { start, end } = value;
  start = start.format('YYYY-MM-DD');
  end = end.format('YYYY-MM-DD');

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);

      setTimeout(() => {
        const request = {
          viewID: `${VIEW_ID()}`,
          start,
          end,
          metrics: 'ga:sessions',
          dimensions: ['ga:date'],
        };

        queryReport(request).then((resp) => {
          const result = resp.result.reports[0].data.rows;
          const label = result.map((e) =>
            [
              e.dimensions[0].slice(0, 4),
              '-',
              e.dimensions[0].slice(-4, -2),
              '-',
              e.dimensions[0].slice(-2),
            ].join(''),
          );
          const dataset = result.map((e) => +e.metrics[0].values[0]);
          let DataCopy = Data;
          DataCopy.data.labels = label;
          DataCopy.data.datasets[0].data = dataset;
          setData(DataCopy);
          setIsSession(true);
        });
      }, 1000);

      setTimeout(() => {
        const request1 = {
          viewID: `${VIEW_ID()}`,
          start,
          end,
          metrics: 'ga:7dayUsers',
          dimensions: ['ga:date'],
        };
        queryReport(request1).then((resp) => {
          const result = resp.result.reports[0].data.rows;
          const dataset = result.map((e) => +e.metrics[0].values[0]);
          let DataCopy = Data;
          DataCopy.data.datasets[1].data = dataset;
          setData(DataCopy);
          setActiveUser(true);
        });
      }, 1500);
    }
  }, [Data, end, isLoaded, start]);

  return (
    <div className="col-md-6">
      <Card sx={{ minWidth: 275 }}>
        <CardHeader title="Average Daily Sessions Per Daily Active User"></CardHeader>
        <Divider />
        <CardContent>{isSession && isActiveUser ? <Line {...Data} /> : 'Loading...'}</CardContent>
      </Card>
    </div>
  );
};

export default AverageDailySessions;
