// Approval api
import * as approvalAPI from '../../api/ApprovalAPI';

import { ApprovalActionType } from '../constants/FeaturedPostActionType';

export const getFeaturedPosts = async ({ body, dispatch }) =>
  dispatch({
    type: ApprovalActionType.GET_FEATURED_POSTS_LIST,
    payload: await approvalAPI.getApprovals(body),
  });

export const getFeaturedPostsApproved = async ({ body, dispatch }) =>
  dispatch({
    type: ApprovalActionType.GET_FEATURED_POSTS_LIST_APPROVED,
    payload: await approvalAPI.getApprovals(body),
  });

export const approvePost = async (ID, { dispatch }) =>
  dispatch({
    type: ApprovalActionType.APPROVE_POST,
    payload: await approvalAPI.approvePost(ID),
  });

export const rejectPost = async (ID, { dispatch }) =>
  dispatch({
    type: ApprovalActionType.APPROVE_POST,
    payload: await approvalAPI.rejectPost(ID),
  });

export const userFirstTimeApproved = async (userId, { dispatch }) => {
  const body = { time_approval: true };
  dispatch({
    type: ApprovalActionType.FIRSTTIME_APPROVED_USER_FULFILLED,
    payload: await approvalAPI.userFirstTimeApproved(body, userId),
    params: userId,
  });
};

export const reApplyApproval = async (body, approvalId, { dispatch }) => {
  dispatch({
    type: ApprovalActionType.REAPPLY_APPROVED_USER_FULFILLED,
    payload: await approvalAPI.reApplyApproval(body, approvalId),
  });
};

export const getPastApprovalReq = async (userId, { dispatch }) => {
  dispatch({
    type: ApprovalActionType.PAST_APPROVAL_REQUESTS_FULFILLED,
    payload: await approvalAPI.getPastApprovalReq(userId),
  });
};
