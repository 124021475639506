import React, { useState } from 'react';
import DateRangePicker from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import moment from 'moment';

const DateRange = ({ value, setValue }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSelect = (value) => {
    setValue(value);
    setIsOpen(!isOpen);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const renderSelectionValue = () => {
    return (
      <div>
        {value.start.format('MM/DD')}
        {' - '}
        {value.end.format('MM/DD')}
      </div>
    );
  };

  return (
    <div>
      <div onBlur={() => setIsOpen(false)} onClick={onToggle}>
        {renderSelectionValue()}
      </div>
      {isOpen && (
        <DateRangePicker
          value={value}
          onSelect={onSelect}
          singleDateRange={true}
          maxDate={new Date()}
        />
      )}
    </div>
  );
};

export default DateRange;
