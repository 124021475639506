import React, { useContext, useState, useEffect } from 'react';

// redux features
import { store } from 'store';
import * as FeaturedPostAction from 'store/actions/FeaturedPostActions';
import * as UsersAction from 'store/actions/UsersActions';

import { status } from 'config';

const ApprovalModal = ({ setShowApprovalModal, history }) => {
  const globalState = useContext(store);
  const { userDetails, currentUser } = globalState.state.usersState;
  const { featuredPostDispatch, usersDispatch } = globalState.dispatch;
  const [btnstyle, setBtnstyle] = useState(false);
  const defaultValues = {
    link_toggle: false,
    link_delete_toggle: false,
    about_toggle: false,
    about_delete_toggle: false,
    image_toggle: false,
    image_delete_toggle: false,
    link_reason: '',
    about_reason: '',
    image_reason: '',
    custom_info: '',
  };

  const [form, setForm] = useState(defaultValues);

  const handleInputChange = (e, str) => {
    if (str) {
      const value = e.target.value === 'false' ? false : true;
      setForm({ ...form, [e.target.name]: JSON.parse(!value) });
    } else setForm({ ...form, [e.target.name]: e.target.value });

    setBtnstyle(true);
  };

  let id = history.location.pathname.split('/')[2];

  const handleSubmit = () => {
    const dispatch = { dispatch: featuredPostDispatch };

    for (const elem in form) if (!form[elem]) delete form[elem];

    if (Object.keys(form)) {
      let body = { approval: { ...form, status: 'denied' } };
      return (
        FeaturedPostAction.userFirstTimeApproved(userDetails.id, dispatch),
        UsersAction.reApplyApproval(body, userDetails.approval.id, dispatch).then((res) => {
          UsersAction.getUser({ id, dispatch: usersDispatch });
        }),
        setShowApprovalModal(false)
      );
    }
  };

  return (
    <>
      <div
        className={`modal fade lps-modal lps-modal-approvel apprval_modal_wrap ${
          setShowApprovalModal ? 'show' : ''
        }`}
        id="deleteModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
        s
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <form className="post-form">
                <div className="row">
                  <div className="col-md-4 appovel-left-col">
                    <div className="approvel-content">
                      <article>
                        <h3 className="modal-title">
                          Your application was not approved at this time. However, we would love for
                          you to reapply!
                        </h3>
                        <p className="modal-para">
                          Your application was either missing information or violated our community
                          guidelines.
                        </p>
                      </article>
                      <div className="form-group">
                        <div className="textarea-count-wrapper">
                          <textarea
                            className="form-control textarea-input"
                            rows="4"
                            value={form.custom_info}
                            name="custom_info"
                            onChange={(e) => handleInputChange(e)}
                            placeholder="add a short custom message (optional)"
                          ></textarea>
                          <span className="textRange">
                            {form && form.custom_info ? form.custom_info.length : 0}/200
                          </span>
                        </div>
                      </div>
                      <article>
                        <p className="modal-para">
                          Please re-read our community guidelines before resubmitting your
                          application.
                        </p>
                        <p className="modal-para">
                          Remember, you can also be a part of the community as a fan without posting
                          content. If you think this is a better fit for you, just close this window
                          and browse.
                        </p>
                      </article>
                    </div>
                  </div>
                  <div className="col-md-8 appovel-border-left">
                    <div className="approvel-content">
                      {userDetails.approval.about ? (
                        <>
                          <div className="switch-wrapper">
                            <div className="switch-inline">
                              <label className="switch-label">needs revision</label>
                              <label className="lps-switch">
                                <input
                                  type="checkbox"
                                  name="about_toggle"
                                  value={form.about_toggle}
                                  onChange={(e) => handleInputChange(e, 'chk')}
                                />
                                <span className="lps-slider lps-round"></span>
                              </label>
                            </div>
                            {form.about_toggle ? (
                              <div className="switch-inline">
                                <label className="switch-label">delete user text</label>
                                <label className="lps-switch">
                                  <input
                                    type="checkbox"
                                    name="about_delete_toggle"
                                    value={form.about_delete_toggle}
                                    onChange={(e) => handleInputChange(e, 'chk')}
                                  />
                                  <span className="lps-slider lps-round"></span>
                                </label>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <article>
                                <p className="approval_about">{userDetails.approval.about}</p>
                              </article>
                            </div>
                            {!form.about_toggle ? (
                              <div className="col-md-6">
                                <p className="text-success">Sounds good!!</p>
                              </div>
                            ) : (
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="textarea-count-wrapper">
                                    <textarea
                                      className="form-control textarea-input"
                                      rows="2"
                                      name="about_reason"
                                      value={form.about_reason}
                                      onChange={(e) => handleInputChange(e)}
                                      placeholder="type a short message explaining what’s missing (optional)"
                                    ></textarea>
                                    <span className="textRange">
                                      {form && form.about_reason ? form.about_reason.length : 0}/200
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <hr className="lps-hr" />
                        </>
                      ) : (
                        ''
                      )}
                      {userDetails.approval.attachments.length ? (
                        <>
                          <div className="switch-wrapper">
                            <div className="switch-inline">
                              <label className="switch-label">needs revision</label>
                              <label className="lps-switch">
                                <input
                                  type="checkbox"
                                  name="image_toggle"
                                  value={form.image_toggle}
                                  onChange={(e) => handleInputChange(e, 'chk')}
                                />
                                <span className="lps-slider lps-round"></span>
                              </label>
                            </div>
                            {form.image_toggle ? (
                              <div className="switch-inline">
                                <label className="switch-label">delete user image</label>
                                <label className="lps-switch">
                                  <input
                                    type="checkbox"
                                    name="image_delete_toggle"
                                    value={form.image_delete_toggle}
                                    onChange={(e) => handleInputChange(e, 'chk')}
                                  />
                                  <span className="lps-slider lps-round"></span>
                                </label>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="thumb_img_grid">
                                <div className="thumb_img_grid">
                                  <div className="feature_img_thumbnail">
                                    {userDetails.approval.attachments.map((img, index) => (
                                      <div className="feature_item" key={index}>
                                        <figure className="feature_fig">
                                          <img src={img.photo_urls.original} alt="thumbnail" />
                                        </figure>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!form.image_toggle ? (
                              <div className="col-md-6">
                                <p className="text-success">Love these!</p>
                              </div>
                            ) : (
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="textarea-count-wrapper">
                                    <textarea
                                      className="form-control textarea-input"
                                      rows="2"
                                      name="image_reason"
                                      value={form.image_reason}
                                      onChange={(e) => handleInputChange(e)}
                                      placeholder="type a short message explaining what’s missing (optional)"
                                    ></textarea>
                                    <span className="textRange">
                                      {form && form.image_reason ? form.image_reason.length : 0}/200
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <hr className="lps-hr" />
                        </>
                      ) : (
                        ''
                      )}
                      {userDetails.approval.link ? (
                        <>
                          <div className="switch-wrapper">
                            {userDetails.approval.link ? (
                              <div className="switch-inline">
                                <label className="switch-label">needs revision</label>
                                <label className="lps-switch">
                                  <input
                                    type="checkbox"
                                    name="link_toggle"
                                    value={form.link_toggle}
                                    onChange={(e) => handleInputChange(e, 'chk')}
                                  />
                                  <span className="lps-slider lps-round"></span>
                                </label>
                              </div>
                            ) : (
                              ''
                            )}
                            {form.link_toggle ? (
                              <div className="switch-inline">
                                <label className="switch-label">delete user link</label>
                                <label className="lps-switch">
                                  <input
                                    type="checkbox"
                                    name="link_delete_toggle"
                                    value={form.link_delete_toggle}
                                    onChange={(e) => handleInputChange(e, 'chk')}
                                  />
                                  <span className="lps-slider lps-round"></span>
                                </label>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <a
                                href={userDetails.approval.link}
                                target="_blank"
                                className="lps-link d-block lps-link-daek"
                              >
                                {userDetails.approval.link}
                              </a>
                            </div>
                            {!form.link_toggle ? (
                              <div className="col-md-6">
                                <p className="text-success">Can't wait to see this on Lips!</p>
                              </div>
                            ) : (
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="textarea-count-wrapper">
                                    <textarea
                                      className="form-control textarea-input"
                                      rows="2"
                                      name="link_reason"
                                      value={form.link_reason}
                                      onChange={(e) => handleInputChange(e)}
                                      placeholder="type a short message explaining what’s missing (optional)"
                                    ></textarea>
                                    <span className="textRange">
                                      {form && form.link_reason ? form.link_reason.length : 0}/200
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="lps-btn-wrapper">
                      <button
                        type="button"
                        className={`${btnstyle ? 'btn-primary' : 'btn_drak_gray'} btn  `}
                        onClick={handleSubmit}
                      >
                        send
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => setShowApprovalModal(false)}
                      >
                        cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovalModal;
