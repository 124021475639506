import React, { useState, useEffect } from 'react';

import originalMoment from 'moment';
import { extendMoment } from 'moment-range';

import { queryReport } from '../../../apiBody/queryReport';
import Card from '../Card';

import { VIEW_ID } from 'config';

const moment = extendMoment(originalMoment);

const Report = () => {
  const [reportData, setReportData] = useState();
  let returningCount = 0;
  let totalCount = 0;

  const today = moment();
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, 'days'), today.clone()),
  );

  let { start, end } = value;
  start = start.format('YYYY-MM-DD');
  end = end.format('YYYY-MM-DD');

  const displayResults = (response) => {
    let newReportData = [];
    const queryResult = response.result.reports[0].data.rows;

    if (
      response.result.reports[0].columnHeader.metricHeader.metricHeaderEntries[0].name ===
      'ga:users'
    ) {
      queryResult.forEach((row) => {
        if (row.dimensions[0] === 'Returning Visitor') {
          returningCount = returningCount + parseInt(row.metrics[0].values[0]);
        }
      });
    } else if (
      response.result.reports[0].columnHeader.metricHeader.metricHeaderEntries[0].name ===
      'ga:newUsers'
    ) {
      totalCount = response.result.reports[0].data.totals[0].values;
    }

    if (returningCount) {
      newReportData.push([{ title: 'Abandonment User', count: `${returningCount - totalCount}` }]);
      setReportData(newReportData);
    }
  };

  useEffect(() => {
    setReportData([]);
    const request = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:users',
      dimensions: ['ga:userType', 'ga:date'],
    };
    setTimeout(() => {
      queryReport(request).then((resp) => displayResults(resp));
    }, 3000);

    const request1 = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:newUsers',
      dimensions: ['ga:date'],
    };
    setTimeout(() => queryReport(request1).then((resp) => displayResults(resp)), 1000);
  }, [value]);

  return (
    <>
      {reportData && reportData.length > 0 ? (
        reportData.map((data, i) => (
          <Card
            key={i}
            data={data}
            isRegistration={false}
            pageTitle={'Abandonment User'}
            value={value}
            setValue={setValue}
          ></Card>
        ))
      ) : (
        <Card
          data={reportData}
          isRegistration={false}
          pageTitle={'Abandonment User'}
          value={value}
          setValue={setValue}
        ></Card>
      )}
    </>
  );
};

export default Report;
