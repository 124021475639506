import axios from 'axios';
import { API_VIRSION } from 'config';
// temporary user api
export const getReportedPosts = (params) =>
  axios.get(`${API_VIRSION}/admin/post/report`, { params });

export const deleteReportedPost = (id, body) =>
  axios.put(`${API_VIRSION}/admin/post/report/${id}`, body);

export const revertReportedPost = (id) =>
  axios.put(`${API_VIRSION}/admin/post/report/${id}/revert`);

export const filterReportedPosts = (params) =>
  axios.get(`${API_VIRSION}/admin/post/report`, { params });
