import { HashtagActionType } from 'store/constants/HashtagActionType';

const hashtagsInitialState = {
  hashtagList: [],
  status: 'init',
  count: 1,
};

const sortUsers = (styles) => {
  return styles.sort((newStyle, oldStyle) => oldStyle.id - newStyle.id);
};

const HashtagsReducer = (state = hashtagsInitialState, action = {}) => {
  let hashtags = [];
  let index;
  switch (action.type) {
    case HashtagActionType.GET_HASHTAG_LIST:
      return {
        ...state,
        hashtagList: sortUsers(action.payload.hashtags),
        count: action.payload.count,
        status: 'added',
      };

    case HashtagActionType.ADD_HASHTAG:
      return {
        ...state,
        hashtagList: sortUsers([action.payload.hashtag, ...state.hashtagList]),
      };

    case HashtagActionType.UPDATE_HASHTAG:
      hashtags = [...state.hashtagList];
      index = hashtags.findIndex((hash) => hash.id === action.payload.hashtag.id);
      hashtags[index] = action.payload.hashtag;
      return {
        ...state,
        hashtagList: hashtags,
        status: 'updated',
      };

    case HashtagActionType.FILTER_HASHTAGS_FULFILLED:
      return {
        ...state,
        hashtagList: action.payload.hashtags,
        status: 'updated_users',
        count: action.payload.count,
      };

    case HashtagActionType.DELETE_HASHTAGS_FULFILLED:
      hashtags = [...state.hashtagList];
      hashtags.splice(
        hashtags.findIndex((hashtag) => hashtag.id === action.params.id),
        1,
      );
      return {
        ...state,
        hashtagList: hashtags,
        status: 'deleted',
      };

    default:
      return state;
  }
};

export { HashtagsReducer, hashtagsInitialState };
