import React, { useState, useEffect } from 'react';

import { checkSignedIn } from 'config';

//components
import ExploreVisitCard from './components/cards/pageVisit/explore/explore';
import ProfileVisitCard from './components/cards/pageVisit/Profile/Profile';
import RegistrationCard from './components/cards/registration';
import ReturnedUserCard from './components/cards/returnedUserAfter1Session';
import AbandonmentUserCard from './components/cards/abandonmentUserAfter1Session';
import RententionRateCard from './components/cards/retentionRate';
import DropedRegistrationUserCard from './components/cards/dropOffRegistration';
import ActiveCard from './components/cards/activeUsers';
import AverageDailySessions from './components/AverageDailySessions';
import MonthlyData from './components/monthlyData';

import './dashboard.scss';

const DashBoard = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  const renderButton = () => {
    window.gapi.signin2.render('signin-button', {
      scope: 'profile email',
      width: 240,
      height: 50,
      longtitle: true,
      theme: 'dark',
      onsuccess: onSuccess,
      onfailure: onFailure,
    });
  };

  const onSuccess = (googleUser) => {
    console.log('Logged in as: ' + googleUser.getBasicProfile().getName());
  };

  const onFailure = (error) => {
    console.error(error);
  };

  const updateSignin = (signedIn) => {
    //(3)
    setIsSignedIn(signedIn);
    if (!signedIn) {
      renderButton();
    }
  };

  const init = () => {
    //(2)
    checkSignedIn()
      .then((signedIn) => {
        updateSignin(signedIn);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    window.gapi.load('auth2', init); //(1)
  });

  return (
    <>
      {!isSignedIn ? (
        <div id="signin-button">{renderButton}</div>
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <ExploreVisitCard></ExploreVisitCard>
                <ProfileVisitCard></ProfileVisitCard>
                {/* <AbandonmentUserCard></AbandonmentUserCard> */}
                <RegistrationCard> </RegistrationCard>

                <ReturnedUserCard></ReturnedUserCard>
              </div>
              <div className="row">
                <DropedRegistrationUserCard></DropedRegistrationUserCard>
                <ActiveCard></ActiveCard>
                <RententionRateCard></RententionRateCard>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <AverageDailySessions />
            <MonthlyData></MonthlyData>
          </div>
        </>
      )}
    </>
  );
};

export default DashBoard;
