import { SuggestedHashtagActionType } from 'store/constants/SuggestedHashtagActionType';

const suggestedhashtagsInitialState = {
  suggestedHashtagList: [],
  status: 'init',
  count: 1,
};

const sortUsers = (styles) => {
  return styles.sort((newStyle, oldStyle) => oldStyle.id - newStyle.id);
};

const SuggestedHashtagsReducer = (state = suggestedhashtagsInitialState, action = {}) => {
  let hashtags = [];
  let index;
  switch (action.type) {
    case SuggestedHashtagActionType.GET_SUGGETED_HASHTAG_LIST:
      return {
        ...state,
        suggestedHashtagList: sortUsers(action.payload.hashtags),
        count: action.payload.count,
        status: 'added',
      };
    case SuggestedHashtagActionType.APPROVE_HASHTAG_FULFILLED:
      hashtags = [...state.suggestedHashtagList];
      index = hashtags.findIndex((e) => e.id === action.payload.hashtag.id);
      hashtags[index]['approved'] = action.payload.hashtag.approved;
      return {
        ...state,
        suggestedHashtagList: sortUsers(hashtags),
        status: 'updated',
      };

    default:
      return state;
  }
};

export { SuggestedHashtagsReducer, suggestedhashtagsInitialState };
