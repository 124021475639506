import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, ButtonGroup, Avatar } from '@material-ui/core';
import CommonTable from 'components/shared/common-table/CommonTable';
import { TextTruncate } from 'utils/TextTruncate';
import { REPORT_STATUS } from 'config';

import ReportModel from './ReportModel';

const ReportsTable = ({
  deleteReportedPost,
  revertReportedPost,
  totalCount,
  pagination,
  ignoreReportedPost,
  setPagination,
  reports,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [currentReport, setCurrentReport] = useState({});
  const [reportsData, setReportsData] = useState([]);
  const [usersShow, setUsersShow] = useState(false);

  const history = useHistory();
  const keys = [
    'id',
    'Post author',
    'report privacy',
    'Reported by',
    'Reason',
    'Status',
    'Action',
    '',
  ];

  const featuredpostTableData = useCallback(() => {
    return reports.map((report) => {
      return [
        currentReport.id !== report.id ? (
          report.report[0].id
        ) : (
          <>
            {report.report[0].id}
            <div class="user-data-list">
              <div class="user-data-new-td">
                {report.type == 'image' && report.attachments ? (
                  <div className="user-img-wrapper">
                    <img alt="Natacha" src={report.attachments[0].photo_urls.small} />
                  </div>
                ) : (
                  <div className="user-text-wrapper">{report.description}</div>
                )}
              </div>
            </div>
          </>
        ),

        currentReport.id !== report.id ? (
          <div className="d-inline user-img">
            <Avatar alt="Natacha" className="float-left mr-1" src={report.user.photo_urls.medium} />
            <span>{report.user.user_name}</span>
          </div>
        ) : (
          <>
            <div className="d-inline user-img">
              <Avatar
                alt="Natacha"
                className="float-left mr-1"
                src={report.user.photo_urls.medium}
              />
              <span>{report.user.user_name}</span>
            </div>
            <div class="user-data-list">
              <div class="user-data-new-td user-text-wrapper post-descritpiton">
                {report.type == 'image' ? report.description : ''}
              </div>
            </div>
          </>
        ),

        currentReport.id !== report.id ? (
          report.user.privacy_settings
        ) : (
          <>
            {report.user.privacy_settings}
            <div class="user-data-list">
              <div class="user-data-new-td">{'  '}</div>
            </div>
          </>
        ),

        currentReport.id !== report.id ? (
          <span>
            {' '}
            {report.report.length > 1
              ? `${report.report.length} users`
              : `${report.report.length} user`}
          </span>
        ) : (
          <>
            {report.report.length > 1
              ? `${report.report.length} users`
              : `${report.report.length} user`}
            <div class="user-data-list">
              <div class="user-data-new-td">
                {report.report.length > 6
                  ? !usersShow
                    ? report.report.slice(0, 6).map((user) => (
                        <>
                          <div className="username-list">
                            <div className="username">{user.reported_by.user_name}</div>
                          </div>
                        </>
                      ))
                    : report.report.map((user) => (
                        <>
                          <div className="username-list">
                            <div className="username">{user.reported_by.user_name}</div>
                          </div>
                        </>
                      ))
                  : report.report.map((user) => (
                      <>
                        {' '}
                        <div className="username-list">
                          <div className="username">{user.reported_by.user_name}</div>
                        </div>
                      </>
                    ))}
                <span className="lps-link">
                  {report.report.length > 6 ? (
                    !usersShow ? (
                      <span onClick={() => setUsersShow(true)}>see more</span>
                    ) : (
                      <span
                        onClick={() => {
                          return setUsersShow(false);
                        }}
                      >
                        see less
                      </span>
                    )
                  ) : (
                    ''
                  )}
                </span>
              </div>
            </div>
          </>
        ),

        currentReport.id !== report.id ? (
          report.report[0].reason
        ) : (
          <>
            {report.report[0].reason}
            <div class="user-data-list">
              <div class="user-data-new-td">
                {report.report.length > 6
                  ? !usersShow
                    ? report.report.slice(0, 6).map((user) => (
                        <div className="username-list">
                          <div className="text-initail">
                            {TextTruncate({
                              str: user.copyright_link
                                ? user.copyright_link
                                : user.harmful_content
                                ? user.harmful_content
                                : 'User has not added any input',
                              limit: 35,
                            })}
                          </div>
                        </div>
                      ))
                    : report.report.map((user) => (
                        <div className="username-list">
                          <div className="text-initail">
                            {TextTruncate({
                              str: user.copyright_link
                                ? user.copyright_link
                                : user.harmful_content
                                ? user.harmful_content
                                : 'User has not added any input',
                              limit: 35,
                            })}
                          </div>
                        </div>
                      ))
                  : report.report.map((user) => (
                      <div className="text-initail-list">
                        <div className="text-initail">
                          {TextTruncate({
                            str: user.copyright_link
                              ? user.copyright_link
                              : user.harmful_content
                              ? user.harmful_content
                              : 'User has not added any input',
                            limit: 35,
                          })}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </>
        ),

        currentReport.id !== report.id ? (
          REPORT_STATUS[report.report[0].status]
        ) : (
          <>
            {REPORT_STATUS[report.report[0].status]}
            <div class="user-data-list">
              <div class="user-data-new-td"></div>
            </div>
          </>
        ),

        currentReport.id === report.id && report.report[0].status === 'requested' ? (
          <div className="action-less-btns">
            <button className="action_btn" onClick={() => setIsShown(true)}>
              Delete Post
            </button>
            <button className="action_btn" onClick={() => ignoreReportedPost('ignored', report.id)}>
              Ignore
            </button>
            <button
              className="action_btn"
              onClick={() => {
                return setCurrentReport({}), setUsersShow(false);
              }}
            >
              See Less
            </button>
          </div>
        ) : currentReport.id === report.id ? (
          <div className="action-less-btns">
            <button className="action_btn" onClick={() => revertReportedPost(report.id)}>
              Revert
            </button>
            <button
              className="action_btn"
              onClick={() => {
                return setCurrentReport({}), setUsersShow(false);
              }}
            >
              See Less
            </button>
          </div>
        ) : (
          <a href="#" className="lps-link" id="button" onClick={() => setCurrentReport(report)}>
            see more
          </a>
        ),
      ];
    });
  }, [currentReport.id, reports, revertReportedPost, usersShow]);

  useEffect(() => {
    let featuredpostRowData = featuredpostTableData(reports);
    if (JSON.stringify(featuredpostRowData) !== JSON.stringify(reportsData)) {
      setReportsData(featuredpostRowData);
    }
  }, [featuredpostTableData, reports, reportsData, usersShow]);

  return (
    <>
      {' '}
      <ReportModel
        {...{ currentReport, isShown, setIsShown, deleteReportedPost, setCurrentReport }}
      />
      <CommonTable
        totalCount={totalCount}
        customPagination={pagination}
        setCustomPagination={setPagination}
        data={reportsData}
        keys={keys}
      ></CommonTable>
    </>
  );
};

export default ReportsTable;
