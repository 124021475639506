import React, { useState, useEffect } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import originalMoment from 'moment';
import { extendMoment } from 'moment-range';

import { queryReport } from '../../apiBody/queryReport';

import { VIEW_ID } from 'config';

var newReportData = [];
var globalCount = 0;
const AverageDailySessions = () => {
  const moment = extendMoment(originalMoment);
  const [reportData, setReportData] = useState([]);
  const [reportUpdateCount, setReportupdateCount] = useState(0);
  const [value, setValue] = useState(
    moment.range(moment().startOf('month'), moment().startOf('d')),
  );

  let { start, end } = value;
  start = start.format('YYYY-MM-DD');
  end = end.format('YYYY-MM-DD');

  const useStyles = makeStyles({
    row: {
      height: 54,
      padding: '33px',
    },
  });

  const classes = useStyles();

  useEffect(() => {
    // Explore Page Visitor
    const request = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:totalEvents',
      dimensions: ['ga:eventCategory', 'ga:eventAction', 'ga:date'],
    };
    setTimeout(() => {
      let count = 0;
      queryReport(request).then((response) => {
        const queryResult = response.result.reports[0].data.rows;
        queryResult.forEach((row) => {
          if (row.dimensions[1] === '/Explore') {
            count = count + parseInt(row.metrics[0].values[0]);
          }
        });
        newReportData.push({ title: `Explore Page Visitor`, count: `${count}` });

        setReportupdateCount(globalCount++);
      });
    }, 2500);

    // Profile Page Visitor
    const request1 = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:totalEvents',
      dimensions: ['ga:eventCategory', 'ga:eventAction', 'ga:date'],
    };
    setTimeout(() => {
      let count = 0;
      queryReport(request1).then((response) => {
        const queryResult = response.result.reports[0].data.rows;
        queryResult.forEach((row) => {
          if (row.dimensions[1] === '/Profile') {
            count = count + parseInt(row.metrics[0].values[0]);
          }
        });
        newReportData.push({ title: `Profile Page Visitor`, count: `${count}` });
        setReportupdateCount(globalCount++);
      });
    }, 3000);

    //   No of registrations
    const requestRegistation = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:totalEvents',
      dimensions: ['ga:eventCategory', 'ga:eventAction', 'ga:date'],
    };
    setTimeout(() => {
      let count = 0;
      queryReport(requestRegistation).then((response) => {
        const queryResult = response.result.reports[0].data.rows;
        queryResult.forEach((row) => {
          if (row.dimensions[0] === 'Successfully Registered') {
            count = count + parseInt(row.metrics[0].values[0]);
          }
        });
        newReportData.push({ title: `No Of Registration`, count: `${count}` });
        setReportupdateCount(globalCount++);
      });
    }, 3000);

    // returning visitor
    const requestReturningVisitor = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:users',
      dimensions: ['ga:userType', 'ga:date'],
    };
    setTimeout(() => {
      let count = 0;
      queryReport(requestReturningVisitor).then((response) => {
        const queryResult = response.result.reports[0].data.rows;
        queryResult.forEach((row) => {
          if (row.dimensions[0] === 'Returning Visitor') {
            count = count + parseInt(row.metrics[0].values[0]);
          }
        });
        newReportData.push({ title: `Returning Visitor`, count: `${count}` });
        setReportupdateCount(globalCount++);
      });
    }, 3000);

    // User Dropped Registration
    let registeredUser = 0;
    let initiatedUser = 0;
    const requestDroppedRegistration = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:totalEvents',
      dimensions: ['ga:eventCategory', 'ga:eventAction', 'ga:date'],
    };
    setTimeout(() => {
      queryReport(requestDroppedRegistration).then((response) => {
        const queryResult = response.result.reports[0].data.rows;
        queryResult.forEach((row) => {
          if (row.dimensions[0] === 'Successfully Registered') {
            registeredUser = registeredUser + parseInt(row.metrics[0].values[0]);
          } else if (row.dimensions[0] === 'Registration Initiated') {
            initiatedUser = initiatedUser + parseInt(row.metrics[0].values[0]);
          }
        });
        newReportData.push({
          title: `User Dropped Registration`,
          count: `${initiatedUser - registeredUser}`,
        });
        setReportupdateCount(globalCount++);
      });
    }, 3000);

    // Active users
    let activeUser = 0;
    const requestActiveUsers = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:7dayUsers',
      dimensions: ['ga:date'],
    };
    setTimeout(() => {
      queryReport(requestActiveUsers).then((response) => {
        if (
          response.result.reports[0].columnHeader.metricHeader.metricHeaderEntries[0].name ===
          'ga:7dayUsers'
        ) {
          activeUser = response.result.reports[0].data.totals[0].values[0];
        }
        newReportData.push({ title: `Active Users`, count: `${activeUser}` });
        setReportupdateCount(globalCount++);
      });
    }, 3000);
    return () => {
      newReportData = [];
      globalCount = 0;
      setReportupdateCount(0);
    };
  }, [value]);
  useEffect(() => {
    if (reportUpdateCount == newReportData.length) {
      setReportData(newReportData);
    }
  }, [newReportData, reportUpdateCount]);
  return (
    <div className="col-md-6">
      <Card sx={{ minWidth: 275 }}>
        <CardHeader title="MAU (Monthly Data For All Above Metric)"></CardHeader>
        <Divider />
        <CardContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Metric</TableCell>
                  <TableCell align="right">Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData.length
                  ? reportData.map((data, i) => (
                      <TableRow
                        className={classes.row}
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {data.title}
                        </TableCell>
                        <TableCell align="right">{data.count}</TableCell>
                      </TableRow>
                    ))
                  : ''}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
};

export default AverageDailySessions;
