import { ApprovalActionType } from 'store/constants/FeaturedPostActionType';

const featuredPostInitialState = {
  featuredPostList: [],
  featuredPostListApproved: [],
  pastApprovalReqList: [],
  isLoaded: false,
  status: 'init',
  count: 1,
  count2: 1,
};

const sortUsers = (styles) => {
  return styles.sort((newStyle, oldStyle) => oldStyle.id - newStyle.id);
};

const FeaturedpostReducer = (state = featuredPostInitialState, action = {}) => {
  let approvals = [];
  let index;
  switch (action.type) {
    case ApprovalActionType.GET_FEATURED_POSTS_LIST:
      return {
        ...state,
        featuredPostList: sortUsers(action.payload.approvals),
        count: action.payload.count,
        status: 'added',
        isLoaded: true,
      };

    case ApprovalActionType.GET_FEATURED_POSTS_LIST_APPROVED:
      return {
        ...state,
        featuredPostListApproved: sortUsers(action.payload.approvals),
        count2: action.payload.count,
        status: 'added',
        isLoaded: true,
      };
    case ApprovalActionType.APPROVE_POST:
      approvals = [...state.featuredPostList];
      if (approvals.length) {
        index = approvals.findIndex((e) => action.payload.approval.id === e.approval.id);
        approvals[index].approval = action.payload.approval;
        approvals[index].approval_status = action.payload.approval.status;
      }
      return {
        ...state,
        featuredPostList: sortUsers(approvals),
        status: 'updated',
      };
    case ApprovalActionType.REAPPLY_APPROVED_USER_FULFILLED:
      approvals = [...state.featuredPostList];

      if (approvals.length) {
        index = approvals.findIndex((e) => action.payload.approval.id === e.approval.id);
        approvals[index].approval_status = action.payload.approval.status;
      }
      return {
        ...state,
        featuredPostList: sortUsers(approvals),
        status: 'updated',
      };

    case ApprovalActionType.PAST_APPROVAL_REQUESTS_FULFILLED:
      return {
        ...state,
        pastApprovalReqList: action.payload.approvals.filter((elem) => elem.status !== 'requested'),
        status: 'updated',
      };
    default:
      return state;
  }
};

export { FeaturedpostReducer, featuredPostInitialState };
