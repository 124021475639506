import { format } from 'date-fns';
import { VIEW_ID } from 'config';

export const queryReport = (props) => {
  const { start, end, metrics, dimensions, orderBy, filter } = props;

  const requestDimensions = (dimensions) => {
    let result = [];
    dimensions.forEach((item) => {
      result.push({
        name: item,
      });
    });
    return result;
  };

  return window.gapi.client.request({
    path: '/v4/reports:batchGet',
    root: 'https://analyticsreporting.googleapis.com/',
    method: 'POST',
    body: {
      reportRequests: [
        {
          viewId: `${VIEW_ID()}`,
          filtersExpression: filter,
          dateRanges: [
            {
              // startDate: format(new Date(startDate), "yyyy-MM-dd"),
              // end: format(new Date(end), "yyyy-MM-dd"),
              startDate: start,
              endDate: end,
            },
          ],
          metrics: [
            {
              expression: metrics,
            },
          ],
          dimensions: requestDimensions(dimensions),
          orderBys: orderBy
            ? [
                {
                  fieldName: orderBy.fieldName,
                  sortOrder: orderBy.order,
                },
              ]
            : [],
        },
      ],
    },
  });
};
