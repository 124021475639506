import React, { useState, useEffect } from 'react';

import originalMoment from 'moment';
import { extendMoment } from 'moment-range';

import { queryReport } from '../../../apiBody/queryReport';
import Card from '../Card';

import { VIEW_ID } from 'config';

const moment = extendMoment(originalMoment);

const ExploreReport = () => {
  const [reportData, setReportData] = useState();
  let activeUser = 0;
  let allUser = 0;
  let count = 0;
  const today = moment();
  const [value, setValue] = useState(
    moment.range(today.clone().subtract(7, 'days'), today.clone()),
  );

  let { start, end } = value;
  start = start.format('YYYY-MM-DD');
  end = end.format('YYYY-MM-DD');

  const displayResults = (response) => {
    setReportData([{}]);
    let newReportData = [];

    if (
      response.result.reports[0].columnHeader.metricHeader.metricHeaderEntries[0].name ===
      'ga:7dayUsers'
    ) {
      activeUser = response.result.reports[0].data.totals[0].values[0];
    } else {
      allUser = response.result.reports[0].data.totals[0].values[0];
    }

    newReportData.push([{ count: `${activeUser}` }]);
    setReportData(newReportData);
  };

  useEffect(() => {
    setReportData([]);
    const request = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:users',
      dimensions: ['ga:date'],
    };

    queryReport(request).then((resp) => displayResults(resp));
    const request1 = {
      viewID: `${VIEW_ID()}`,
      start,
      end,
      metrics: 'ga:7dayUsers',
      dimensions: ['ga:date'],
    };
    queryReport(request1).then((resp) => displayResults(resp));
  }, [value]);

  return (
    <>
      {reportData && reportData.length > 0 ? (
        reportData.map((data, i) => (
          <Card
            pageTitle={'Active User'}
            isRegistration={false}
            key={i}
            data={data}
            value={value}
            setValue={setValue}
          ></Card>
        ))
      ) : (
        <Card
          pageTitle={'Active User'}
          isRegistration={false}
          data={reportData}
          value={value}
          setValue={setValue}
        ></Card>
      )}
    </>
  );
};

export default ExploreReport;
