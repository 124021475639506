import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { store } from 'store';
import * as HashtagAction from 'store/actions/HashtagAction';
import HashtagTable from './HashtagList.js';
import Form from '../HashtagComponent/Form';
import Filter from './Filter';
import * as commonService from 'utils/CommonService';

const HashtagList = () => {
  const history = useHistory();
  const defaultPagination = {
    page: 1,
    limit: 10,
    approved_status: true,
  };
  const globalState = useContext(store);
  const { hashtagDispatch } = globalState.dispatch;
  const { hashtagList, status, count } = globalState.state.hashtagState;
  const [rowData, setRowData] = useState([]);
  const [currentHashtag, setCurrentHashtag] = useState(null);
  const [pagination, setDefaultPagination] = useState(defaultPagination);
  const [filterParams, setFilterParams] = useState({});

  useEffect(() => {
    if (!rowData.length && !hashtagList.length) {
      HashtagAction.getHashtags({ body: { ...pagination }, dispatch: hashtagDispatch });
    }
  }, [rowData, hashtagList, status, hashtagDispatch, pagination]);

  useEffect(() => {
    if (rowData.length !== hashtagList.length || status !== 'init') {
      const rows = hashtagList;
      setRowData(rows);
    }
  }, [rowData, status, hashtagList]);

  const handleFilter = (e, filterForm) => {
    e.preventDefault();
    setDefaultPagination({ ...pagination, page: 1 });
    setFilterParams(filterForm);
    HashtagAction.filterHashtags({
      body: { ...pagination, page: 1, ...filterForm },
      dispatch: hashtagDispatch,
    });
  };

  const resetLeadFilterForm = (data) => {
    setFilterParams(data);
    setDefaultPagination({ page: 1, limit: 10 });
    HashtagAction.getHashtags({
      body: { page: 1, limit: 10 },
      dispatch: hashtagDispatch,
    });
  };

  const handleDelete = (hashtag) => {
    commonService.isDialogOpen.onNext({
      open: true,
      data: {
        message: 'Are you sure?',
      },
      cancelText: 'Cancel',
      confirmText: 'Okay',
      onCancel: () => commonService.isDialogOpen.onNext(false),
      onConfirm: () => deleteHashtag(hashtag),
    });
  };

  const deleteHashtag = (hashtag) => {
    if (hashtag)
      HashtagAction.deleteHashtag({ id: hashtag.id, dispatch: hashtagDispatch }).then((res) => {
        commonService.forSuccess('Hashtag deleted successfully!', 'Success');
      });
    commonService.isDialogOpen.onNext(false);
  };

  const setPagination = (params) => {
    setDefaultPagination({ ...pagination, ...params });
    HashtagAction.getHashtags({
      body: { ...pagination, ...params, ...filterParams },
      dispatch: hashtagDispatch,
    });
  };
  return (
    <>
      <div className="p-2">
        <div className="row">
          <div className="col-md-8">
            <Form currentHashtag={currentHashtag} setCurrentHashtag={setCurrentHashtag}></Form>
          </div>
          <div className="col-md-4 text-right">
            <Button
              variant="outlined"
              color="secondary"
              className="btn-default"
              onClick={(e) => history.push('/suggested-hashtag')}
            >
              Suggested Hashtag
            </Button>
          </div>
        </div>
        <Filter resetLeadFilterForm={resetLeadFilterForm} handleFilter={handleFilter} />
        <HashtagTable
          totalCount={count}
          pagination={pagination}
          setPagination={setPagination}
          setCurrentHashtag={setCurrentHashtag}
          hashtagList={rowData}
          hashtagDispatch={hashtagDispatch}
          HashtagAction={HashtagAction}
          handleDelete={handleDelete}
        ></HashtagTable>
      </div>
    </>
  );
};

export default HashtagList;
