export const API_SUB = '/api';
export const API_VIRSION = '/v1';
export const PASSWORD_MIN_LEN = 6;
export const NAME_REGEXP = /^[a-zA-Z]+$/;

export const BASE_URL = () => process.env.REACT_APP_API_URL;

export const VIEW_ID = () => process.env.REACT_APP_VIEW_ID;

export const RetentionRateLink = () => process.env.REACT_APP_RETENTION_RATE_LINK;

const initAuth = () => {
  return window.gapi.auth2.init({
    client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
    scope: 'https://www.googleapis.com/auth/analytics.readonly',
  });
};

export const checkSignedIn = () => {
  return new Promise((resolve, reject) => {
    initAuth() //calls the previous function
      .then(() => {
        const auth = window.gapi.auth2.getAuthInstance(); //returns the GoogleAuth object
        resolve(auth.isSignedIn.get()); //returns whether the current user is currently signed in
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const categories = ['link', 'html'];
export const sub_categories = {
  privacy_policy: 'privacy_policy',
  term_of_service: 'term_of_service',
  about_us: 'about_us',
};
export const link_title_options = {
  fb: 'Facebook',
  twitter: 'Twitter',
  youtube: 'Youtube',
  linkedin: 'LinkedIn',
  instagram: 'Instagram',
  privacy_upload: 'Privacy',
  tos_upload: 'Terms of service',
};
export const roles = ['User', 'Service Provider', 'Superadmin'];
export const status = [
  'requested',
  'accepted',
  'declined',
  'cancelled',
  'expired',
  'completed',
  'payment_failed',
];
export const payment_status = [
  'created',
  'charged',
  'cancelled',
  'payment_failed',
  'refund',
  'accepted',
  'paid_out',
];
export const booking_status = [
  'requested',
  'accepted',
  'declined',
  'cancelled',
  'expired',
  'completed',
  'payment_failed',
];
export const OrderOptions = [
  { value: 'desc', text: 'Newest' },
  { value: 'asc', text: 'Oldest' },
  { value: 'updated_at', text: 'Recently Updated' },
];

export const REPORT_STATUS = {
  deleted_initial: 'deleted',
  appealed_deleted: 'appealed deleted',
  requested: 'requested',
  appealed: ' appealed',
  ignored: 'ignored',
  suggested: 'requested',
  approved: 'approved',
  rejected: 'rejected',
};
export const REQUEST_STATUS = {
  remove_tag: 'remove',
  approved_tag: 'add',
  rejected: 'rejected',
};
