// Hashtag api
import * as suggestedhashtagAPI from '../../api/SuggestedHashtagAPI';

import { SuggestedHashtagActionType } from '../constants/SuggestedHashtagActionType';

export const getSuggestedHashtags = async ({ body, dispatch }) =>
  dispatch({
    type: SuggestedHashtagActionType.GET_SUGGETED_HASHTAG_LIST,
    payload: await suggestedhashtagAPI.getHashtags(body),
  });

export const approveHashtag = async ({ id, dispatch }) =>
  dispatch({
    type: SuggestedHashtagActionType.APPROVE_HASHTAG_FULFILLED,
    payload: await suggestedhashtagAPI.approveHashtag(id),
    params: id,
  });

export const rejectHashtag = async ({ id, dispatch }) =>
  dispatch({
    type: SuggestedHashtagActionType.REJECT_HASHTAG_FULFILLED,
    payload: await suggestedhashtagAPI.rejectHashtag(id),
    params: id,
  });
