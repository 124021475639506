import React, { useState } from 'react';
import * as commonService from 'utils/CommonService.js';

const ReportModel = ({
  currentReport,
  isShown,
  setIsShown,
  approveReportedTag,
  setCurrentReport,
  reqType,
  hashName,
  setUpdatedAddTags,
  setUpdatedRemoveTags,
}) => {
  const [reason, setDefaultReason] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (reason.length >= 25) {
      if (reqType === 'add') {
        setUpdatedAddTags({ reason: reason, add_tag: hashName });
        // const body =
        // {
        //   "suggesttag": {
        //     "reason": reason,
        //     "status": "approved",
        //     "add_tag": [hashName],
        //   }
        // }
        // await approveReportedTag(currentReport, body)
      } else if (reqType === 'remove') {
        setUpdatedRemoveTags({ reason: reason, addTags: hashName });

        // const body = {
        //   "suggesttag": {
        //     "reason": reason,
        //     "status": "approved",
        //     "remove_tag": [hashName]
        //   }
        // }
        // await approveReportedTag(currentReport, body)
      }
      await setIsShown(false);
      await setCurrentReport({});
      await setDefaultReason('');
    } else commonService.forError('Reason is not valid.');
  };

  return (
    <div
      className={`modal fade lps-modal suggest-modal ${isShown ? 'show' : ''}`}
      id="deleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <form className="post-form" onSubmit={handleSubmit}>
              <article>
                <h3 className="modal-title">
                  {reqType === 'remove' ? 'Remove tag from post' : 'Add tag to post'}
                </h3>
                <p className="modal-para">
                  {reqType === 'remove'
                    ? 'We removed #hashtag to your post because it contained'
                    : 'We added #hashtag to your post because it contained'}
                </p>
              </article>
              <div className="form-group input-counter">
                <input
                  value={reason}
                  onChange={(e) => setDefaultReason(e.target.value)}
                  className="form-control"
                  placeholder="enter reason"
                ></input>
                <div className="counter-block">{reason.length}/25</div>
              </div>

              <div className="suggest-para-bottom">
                <p>
                  {reqType === 'remove'
                    ? "You can always go back and add it again, we won't stop you."
                    : 'We believe others want to be warned before viewing it.'}
                </p>
                <p>
                  {reqType === 'remove'
                    ? ' Be mindful and avoid tag spam.'
                    : 'You can request to remove the tag now or later when editing this post.'}
                </p>
              </div>

              <div className="lps-btn-wrapper">
                <button type="submit" className="btn btn-primary">
                  {reqType === 'remove' ? 'Remove' : 'Add tag'}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => setIsShown(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportModel;
