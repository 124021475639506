import React, { useEffect, useState } from 'react';
import DateRangePicker from '../dateRangePicker';
import DateFilter from '../dateFilter';

const StatusCard = ({ pageTitle, isRegistration, data, value, setValue }) => {
  useEffect(() => {}, [value, data]);

  return (
    <>
      <div className="col-sm-3">
        <div className="card card-status">
          <div className="card-body">
            <h5 className="status-date">
              {!isRegistration ? (
                <DateRangePicker value={value} setValue={setValue}></DateRangePicker>
              ) : (
                <DateFilter value={value} setValue={setValue}></DateFilter>
              )}
            </h5>
            <div className="status-icon-wrap">
              <span className="status-icon status-icon-primary">
                <svg
                  className="MuiSvgIcon-root MuiBox-root jss114"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4zm2.5 2.1h-15V5h15v14.1zm0-16.1h-15c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                </svg>
              </span>
            </div>
            <h2 className="card-status-title">{data && data[0] ? data[0].count : 0}</h2>
            <h6 className="card-status-subtitle text-muted">{pageTitle}</h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusCard;
