import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@material-ui/core';

import CommonTable from 'components/shared/common-table/CommonTable';

const SuggestedHashtagTable = ({
  approveHashtag,
  totalCount,
  pagination,
  setPagination,
  suggestedHashtagList,
  suggestedhashtagDispatch,
}) => {
  const [suggestedhashtagData, setSuggestedHashtagData] = useState([]);

  const keys = ['id', 'hashtag', 'status'];

  const hashtagTableData = useCallback(() => {
    return suggestedHashtagList.map((hashtag) => {
      return [
        hashtag.id,
        hashtag.name,
        <Button variant="contained" color="primary" onClick={(e) => approveHashtag(hashtag)}>
          {hashtag.approved ? 'Approved' : 'Approve'}
        </Button>,
      ];
    });
  }, [suggestedHashtagList, approveHashtag]);

  useEffect(() => {
    let hashtagRowData = hashtagTableData(suggestedHashtagList);
    if (JSON.stringify(hashtagRowData) !== JSON.stringify(suggestedhashtagData)) {
      setSuggestedHashtagData(hashtagRowData);
    }
  }, [hashtagTableData, suggestedHashtagList, suggestedhashtagData]);
  return (
    <CommonTable
      totalCount={totalCount}
      customPagination={pagination}
      setCustomPagination={setPagination}
      data={suggestedhashtagData}
      keys={keys}
    ></CommonTable>
  );
};

export default SuggestedHashtagTable;
