export const ReportedPostsActionType = {
  GET_REPORTED_POSTS: 'GET_REPORTED_POSTS',
  GET_REPORTED_POSTS_REJECTED: 'GET_REPORTED_POSTS_REJECTED',
  GET_REPORTED_POSTS_FULFILLED: 'GET_REPORTED_POSTS_FULFILLED',

  FILTER_REPORTED_POSTS: 'FILTER_REPORTED_POSTS',
  FILTER_REPORTED_POSTS_REJECTED: 'FILTER_REPORTED_POSTS_REJECTED',
  FILTER_REPORTED_POSTS_FULFILLED: 'FILTER_REPORTED_POSTS_FULFILLED',

  REVERT_REPORTED_POSTS: 'REVERT_REPORTED_POSTS',
  DELETE_REPORTED_POSTS: 'DELETE_REPORTED_POSTS',

  GET_REPORTS_DATA_: 'GET_REPORTS_DATA_',
  GET_REPORTS_DATA__REJECTED: 'GET_REPORTS_DATA__REJECTED',
  GET_REPORTS_DATA__FULFILLED: 'GET_REPORTS_DATA__FULFILLED',
};
