import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
// import custom styles of app
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.scss';
import 'core-js/modules/es6.symbol';
// all routing configuration in main
import Main from './router';

// default base url of api
import { BASE_URL } from './config.js';
import * as serviceWorker from './serviceWorker';
import successHandler from './utils/interceptors/successHandler';
import errorHandler from './utils/interceptors/errorHandler';
import * as commonService from './utils/CommonService.js';

// set default headers and options of axios for api request
axios.defaults.baseURL = BASE_URL();
//axios.defaults.customHeaders = true;
axios.defaults.defaultErrorHandler = true;
axios.defaults.headers.common['Authorization'] = localStorage['authToken']
  ? `Bearer ${localStorage['authToken']}`
  : '';
axios.interceptors.request.use((config) => {
  // show loader
  if (!config.url.includes('/v1/admin/user') && !config.url.includes('chat'))
    commonService.isLoading.onNext(true);
  return config;
});
axios.interceptors.response.use((config) => {
  // hide loader
  commonService.isLoading.onNext(false);
  return config;
});
axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
