import React from 'react';

const ReportModel = ({ isShownViewPost, setISShownViewPost, elem }) => {
  return (
    <div
      className={`modal fade lps-modal suggest-modal viewPostDetailsModal ${
        isShownViewPost ? 'show' : ''
      }`}
      id="deleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="close-icon-wrapper">
              <p
                className="close-link"
                data-dismiss="modal"
                onClick={() => setISShownViewPost(false)}
              >
                <i class="fas fa-times"></i>
              </p>
            </div>
            <div className="post-wrapper">
              {elem ? (
                <figure className="postFig">
                  <img
                    src={elem.photo_urls.original}
                    height="600px"
                    width="600px"
                    alt="Thumbnails"
                  />
                </figure>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportModel;
