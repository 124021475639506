import React, { useState, useEffect, useCallback } from 'react';
import { Button, ButtonGroup, Icon } from '@material-ui/core';

import CommonTable from 'components/shared/common-table/CommonTable';

const HashtagTable = ({
  totalCount,
  pagination,
  setPagination,
  HashtagAction,
  hashtagDispatch,
  hashtagList,
  setCurrentHashtag,
  handleDelete,
}) => {
  const [hashtagData, setHashtagData] = useState([]);

  //    const toggleActive = useCallback(
  //     (checked, user) => {
  //       //user.active_status = checked ? 1 : 0;
  //       UsersAction.toggleActive({ body: { active: checked } , id: user.id, dispatch: usersDispatch });
  //     },
  //     [UsersAction, usersDispatch]
  //   );

  const keys = ['id', 'hashtag', 'Status', 'action'];

  const hashtagTableData = useCallback(() => {
    return hashtagList.map((hashtag) => {
      return [
        hashtag.id,
        hashtag.name,
        hashtag.active_status,
        <ButtonGroup variant="text" color="secondary" aria-label="text primary button group">
          <Button onClick={() => setCurrentHashtag(hashtag)}>
            <Icon color="primary">edit_sharp</Icon>
          </Button>
          <Button onClick={() => handleDelete(hashtag)}>
            <Icon color="secondary">delete_sharp</Icon>
          </Button>
        </ButtonGroup>,
      ];
    });
  }, [hashtagList, setCurrentHashtag, handleDelete]);

  useEffect(() => {
    let hashtagRowData = hashtagTableData(hashtagList);
    if (JSON.stringify(hashtagRowData) !== JSON.stringify(hashtagData)) {
      setHashtagData(hashtagRowData);
    }
  }, [hashtagTableData, hashtagList, hashtagData]);
  return (
    <CommonTable
      totalCount={totalCount}
      customPagination={pagination}
      setCustomPagination={setPagination}
      data={hashtagData}
      keys={keys}
    ></CommonTable>
  );
};

export default HashtagTable;
