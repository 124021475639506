import React, { createContext, useReducer } from 'react';

import { AuthReducer, userAuthInitialState } from './reducers/AuthReducer';
import { HashtagsReducer, hashtagsInitialState } from './reducers/HashtagReducer';
import { UsersReducer, usersInitialState } from './reducers/UsersReducer';

import { FeaturedpostReducer, featuredPostInitialState } from './reducers/FeaturedPostReducer';

import {
  SuggestedHashtagsReducer,
  suggestedhashtagsInitialState,
} from './reducers/SuggestedHashtagReducer';

import { FeaturedAdReducer, featuredadInitialState } from './reducers/FeaturedAdReducer';

import { CouponCodeReducer, couponcodesInitialState } from './reducers/CouponCodeReducer';

import { PostReducer, postInitialState } from './reducers/PostReducer';

import { ReportedPostsReducer, reportInitialState } from './reducers/ReportedPostsReducer';

import {
  ReportedHasgtagReducer,
  reportHasgtagInitialState,
} from './reducers/ReportedHagtagReducer';

const store = createContext({});
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [userAuthState, userAuthDispatch] = useReducer(AuthReducer, userAuthInitialState);
  const [hashtagState, hashtagDispatch] = useReducer(HashtagsReducer, hashtagsInitialState);
  const [usersState, usersDispatch] = useReducer(UsersReducer, usersInitialState);

  const [featuredPostState, featuredPostDispatch] = useReducer(
    FeaturedpostReducer,
    featuredPostInitialState,
  );
  const [suggestedhashtagState, suggestedhashtagDispatch] = useReducer(
    SuggestedHashtagsReducer,
    suggestedhashtagsInitialState,
  );

  const [featuredadState, featuredadDispatch] = useReducer(
    FeaturedAdReducer,
    featuredadInitialState,
  );

  const [couponcodeState, couponcodeDispatch] = useReducer(
    CouponCodeReducer,
    couponcodesInitialState,
  );
  const [postState, postDispatch] = useReducer(PostReducer, postInitialState);
  const [reportState, reportDispatch] = useReducer(ReportedPostsReducer, reportInitialState);

  const [reportHashtagState, reportHashtagDispatch] = useReducer(
    ReportedHasgtagReducer,
    reportHasgtagInitialState,
  );
  return (
    <Provider
      value={{
        state: {
          reportState,
          reportHashtagState,
          userAuthState,
          hashtagState,
          usersState,
          featuredPostState,
          suggestedhashtagState,
          featuredadState,
          couponcodeState,
          postState,
        },
        dispatch: {
          userAuthDispatch,
          hashtagDispatch,
          usersDispatch,
          featuredPostDispatch,
          suggestedhashtagDispatch,
          featuredadDispatch,
          couponcodeDispatch,
          postDispatch,
          reportDispatch,
          reportHashtagDispatch,
        },
      }}
    >
      {children}
    </Provider>
  );
};

export { store, StateProvider };
