import React, { useRef, useState } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
// global app theme
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// private public routes for prevent internal page from unauthenticated user
import { PrivateRoute, PublicRoute } from './routes';

import { StateProvider } from '../store';

import * as RouteConfig from './routeConfig.js'; //configure all path in this file
import ErrorBoundary from './ErrorBoundary';
import Loader from 'components/shared/ui-components/loader/Loader.js';
import ConfirmDialog from 'components/shared/ui-components/common-dialog';

// add font family for material component
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fd3398',
    },
    secondary: {
      main: '#262933',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
});

export default function Main() {
  const { privateRoutes, publicRoutes } = RouteConfig;
  // scroll top on routes change
  const history = createHistory();
  const scrollPosition = useRef(0);
  history.listen((_) => {
    if (_.pathname === '/action-items') {
      window.scrollTo(0, scrollPosition.current);
    } else {
      scrollPosition.current = window.scrollY;
      window.scrollTo(0, 0);
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <StateProvider>
        <ConfirmDialog />
        <Loader />
        <Router history={history}>
          <ErrorBoundary>
            {/* A <Switch> looks through its children <Route>s and
             renders the first one that matches the current URL. */}
            <Switch>
              {privateRoutes.map((privateRouteProps) => (
                <PrivateRoute {...privateRouteProps} />
              ))}
              {publicRoutes.map((publicRouteProps) => (
                <PublicRoute {...publicRouteProps} />
              ))}
              <Route render={() => <Redirect to={{ pathname: '/' }} />} />
            </Switch>
          </ErrorBoundary>
        </Router>
      </StateProvider>
    </ThemeProvider>
  );
}
