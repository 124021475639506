import React, { useState, useContext, useEffect } from 'react';

import { Button } from '@material-ui/core';
import { TextTruncate } from 'utils/TextTruncate';

import ViewPOstModal from './viewPostModal';

import './ReportDetails.css';

const ReportDetails = ({
  hashtagPostDeatils,
  addTagsInPost,
  removeTagsInPost,
  hashtagList,
  approveReportedTag,
  rejectReportedTag,
  setIsShownn,
}) => {
  const [isShownViewPost, setISShownViewPost] = useState(false);
  const [currentReportHashtag, setCurrentReportHashtag] = useState();
  const [reqType, setreqType] = useState('');
  const [hashNames, setHashNames] = useState('');
  const [viewDesc, setViewDesc] = useState(false);
  const [showUser, setShowuser] = useState(false);
  const [user, SetUser] = useState([]);
  const [currentHaghtag, setcurrentHaghtag] = useState('');
  let [updatedAddTags, setUpdatedAddTags] = useState([]);
  let [updatedRemoveTags, setUpdatedRemoveTags] = useState([]);
  let [updatedIgnoreAddTags, setUpdatedIgnoreAddTags] = useState([]);
  let [updatedIgnoreRemoveTags, setUpdatedIgnoreRemoveTags] = useState([]);
  let [updatedRemoveTagsAdmin, setUpdatedRemoveTagsAdmin] = useState([]);

  var approveTagSuggest = [];
  var approveTagApproved = [];

  var RemoveTagSuggest = [];
  var RemoveTagApproved = [];

  let savedAddReports = localStorage.getItem(`${hashtagPostDeatils.id} Add`, null);
  var addReqArray = savedAddReports ? savedAddReports.split(',').map(String) : [];

  let savedAddIgnoreReports = localStorage.getItem(`${hashtagPostDeatils.id} AddIgnore`, null);
  var addIgnoreReqArray = savedAddIgnoreReports ? savedAddIgnoreReports.split(',').map(String) : [];

  let savedRemoveTagAdminReports = localStorage.getItem(
    `${hashtagPostDeatils.id} RemoveTagAdmin`,
    null,
  );
  var removeTagAdminReqArray = savedRemoveTagAdminReports
    ? savedRemoveTagAdminReports.split(',').map(String)
    : [];

  const handleClickUndoAdd = async (id, elem, STR) => {
    if (STR === 'ADD') {
      addReqArray = addReqArray.filter((savedTag) => {
        if (savedTag !== elem.hashtag_name) {
          return savedTag;
        }
      });

      await localStorage.removeItem(`${id} Add`);
      if (addReqArray.length) {
        localStorage.setItem(`${id} Add`, [addReqArray]);
      }
      return addReqArray;
    } else if (STR === 'IGNORE') {
      addIgnoreReqArray = addIgnoreReqArray.filter((savedTag) => {
        if (savedTag !== elem.hashtag_name) {
          return savedTag;
        }
      });

      await localStorage.removeItem(`${id} AddIgnore`);
      if (addIgnoreReqArray.length) {
        localStorage.setItem(`${id} AddIgnore`, [addIgnoreReqArray]);
      }

      return addIgnoreReqArray;
    } else if (STR === 'UPDATE') {
      updatedAddTags = updatedAddTags.filter((savedTag) => {
        if (savedTag !== elem.hashtag_name) {
          return savedTag;
        }
      });
      setUpdatedAddTags(updatedAddTags);

      return updatedAddTags;
    } else if (STR === 'UPDATEIGNORE') {
      updatedIgnoreAddTags = updatedIgnoreAddTags.filter((savedTag) => {
        if (savedTag !== elem.hashtag_name) {
          return savedTag;
        }
      });
      setUpdatedIgnoreAddTags(updatedIgnoreAddTags);
      return updatedIgnoreAddTags;
    }
  };

  const filterRequestStatusForApproveHashtag = () => {
    let allTags = [];

    if (hashtagPostDeatils.hastag_suggest_post) {
      hashtagPostDeatils.hastag_suggest_post.map((e) => {
        e.suggest_tag.map((f) => {
          if (f.status === 'hold' && e.suggest_stauts === 'approved_tag') {
            return allTags.push(Object.assign(f, { userName: e.user.user_name }));
          }
        });
      });

      const counts = {};
      allTags.forEach((x) => {
        counts[x.hashtag_name] = (counts[x.hashtag_name] || 0) + 1;
      });

      let uniqueArray = [...new Map(allTags.map((v) => [v.hashtag_name, v])).values()];

      approveTagSuggest = uniqueArray.map((e, i) => {
        let keys = Object.keys(counts);
        keys.forEach((key) => {
          if (e.hashtag_name === key) {
            Object.assign(e, { count: counts[key] });
          }
        });
        return e;
      });
    }

    const userData = async (hashTagName) => {
      let users = [];

      await allTags.map((elem) => {
        if (elem.hashtag_name === hashTagName) {
          users.push(elem.userName);
        }

        let uniqUsersObject = Array.from(new Set(users));

        SetUser(uniqUsersObject);

        setShowuser(!showUser);
        setcurrentHaghtag(hashTagName);
        return users;
      });
    };

    const userDataCount = (hashTagName) => {
      let userCount = [];
      let uniqUsersObject = [];
      allTags.map((elem) => {
        if (elem.hashtag_name == hashTagName) {
          userCount.push(elem.userName);
        }
        uniqUsersObject = Array.from(new Set(userCount));
      });
      return uniqUsersObject.length;
    };

    return (
      <>
        {' '}
        <thead>
          <tr>
            <th className="adm-lg" colspan="4">
              Add tag requests{' '}
              <span className="text_primary">
                ({approveTagSuggest.length ? approveTagSuggest.length : 0})
              </span>
            </th>
          </tr>
        </thead>
        {approveTagSuggest.map((elem) => {
          return (
            <tbody>
              <tr className="tag-list-active">
                <td>
                  <u>
                    <span onClick={() => userData(elem.hashtag_name)} className="mouse_cursor">
                      {userDataCount(elem.hashtag_name) > 1
                        ? `${userDataCount(elem.hashtag_name)} users`
                        : `${userDataCount(elem.hashtag_name)} user`}
                    </span>{' '}
                  </u>
                  {showUser && currentHaghtag === elem.hashtag_name ? (
                    <div className="showUserListWrapper">
                      <ul className="showUserList ">
                        {user && user.map((userName) => <li>{userName}</li>)}
                      </ul>
                    </div>
                  ) : (
                    ' '
                  )}
                </td>
                <td>{elem.hashtag_name}</td>
                <td>active</td>
                <td className="text-right">
                  {addReqArray &&
                    addReqArray.map((elm) => {
                      if (elm === elem.hashtag_name) {
                        return (
                          <div className="mxBtnRight">
                            {' '}
                            <a
                              href="#"
                              className="btn btn-gray adm-btn"
                              onClick={() => {
                                handleClickUndoAdd(hashtagPostDeatils.id, elem, 'ADD');
                              }}
                            >
                              undo
                            </a>
                          </div>
                        );
                      }
                    })}

                  {updatedAddTags &&
                    updatedAddTags.map((elm) => {
                      if (elm === elem.hashtag_name) {
                        return (
                          <div className="mxBtnRight">
                            {' '}
                            <a
                              href="#"
                              className="btn btn-gray adm-btn"
                              onClick={() => {
                                handleClickUndoAdd(hashtagPostDeatils.id, elem, 'UPDATE');
                              }}
                            >
                              undo
                            </a>
                          </div>
                        );
                      }
                    })}

                  {updatedIgnoreAddTags &&
                    updatedIgnoreAddTags.map((elm) => {
                      if (elm === elem.hashtag_name) {
                        return (
                          <div className="mxBtnRight">
                            {' '}
                            <a
                              href="#"
                              className="btn btn-gray adm-btn"
                              onClick={() => {
                                handleClickUndoAdd(hashtagPostDeatils.id, elem, 'UPDATEIGNORE');
                              }}
                            >
                              undo
                            </a>
                          </div>
                        );
                      }
                    })}

                  {addIgnoreReqArray &&
                    addIgnoreReqArray.map((elm) => {
                      if (elm === elem.hashtag_name) {
                        return (
                          <div className="mxBtnRight">
                            {' '}
                            <a
                              href="#"
                              className="btn btn-gray adm-btn"
                              onClick={() => {
                                handleClickUndoAdd(hashtagPostDeatils.id, elem, 'IGNORE');
                              }}
                            >
                              undo
                            </a>
                          </div>
                        );
                      }
                    })}

                  {addReqArray.find((e) => e === elem.hashtag_name) ||
                  updatedAddTags.find((e) => e === elem.hashtag_name) ||
                  addIgnoreReqArray.find((e) => e === elem.hashtag_name) ||
                  updatedIgnoreAddTags.find((e) => e === elem.hashtag_name) ? null : (
                    <div className="mxBtnRight">
                      <a
                        href="#"
                        className="btn btn-primary adm-btn"
                        onClick={() => {
                          setCurrentReportHashtag(elem.hashtag_name);
                          setreqType('add');
                          updatedAddTags.push(elem.hashtag_name);
                          setUpdatedAddTags(updatedAddTags);
                        }}
                      >
                        add tag
                      </a>
                      <a
                        href="#"
                        className="btn btn-gray adm-btn"
                        onClick={() => {
                          updatedIgnoreAddTags.push(elem.hashtag_name);
                          setUpdatedIgnoreAddTags(updatedIgnoreAddTags);
                          // rejectReportedTag(hashtagPostDeatils.id, elem.hashtag_name, "From add tag")
                        }}
                      >
                        ignore
                      </a>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          );
        })}
      </>
    );
  };

  let savedRemoveReports = localStorage.getItem(`${hashtagPostDeatils.id} Remove`, null);
  var removeReqArray = savedRemoveReports ? savedRemoveReports.split(',').map(String) : [];

  let savedRemoveIgnoreReports = localStorage.getItem(
    `${hashtagPostDeatils.id} RemoveIgnore`,
    null,
  );
  var removeIgnoreReqArray = savedRemoveIgnoreReports
    ? savedRemoveIgnoreReports.split(',').map(String)
    : [];

  const handleClickUndoRemove = async (id, elem, STR) => {
    if (STR === 'REMOVE') {
      removeReqArray = removeReqArray.filter((savedTag) => {
        if (savedTag !== elem.hashtag_name) {
          return savedTag;
        }
      });

      await localStorage.removeItem(`${id} Remove`);
      if (removeReqArray.length) {
        localStorage.setItem(`${id} Remove`, [removeReqArray]);
      }

      return removeReqArray;
    } else if (STR === 'IGNORE') {
      removeIgnoreReqArray = removeIgnoreReqArray.filter((savedTag) => {
        if (savedTag !== elem.hashtag_name) {
          return savedTag;
        }
      });

      await localStorage.removeItem(`${id} RemoveIgnore`);
      if (removeIgnoreReqArray.length) {
        localStorage.setItem(`${id} RemoveIgnore`, [removeIgnoreReqArray]);
      }

      return removeIgnoreReqArray;
    } else if (STR === 'UPDATE') {
      updatedRemoveTags = updatedRemoveTags.filter((savedTag) => {
        if (savedTag !== elem.hashtag_name) {
          return savedTag;
        }
      });
      setUpdatedRemoveTags(updatedRemoveTags);
      return updatedRemoveTags;
    } else if (STR === 'UPDATEIGNORE') {
      updatedIgnoreRemoveTags = updatedIgnoreRemoveTags.filter((savedTag) => {
        if (savedTag !== elem.hashtag_name) {
          return savedTag;
        }
      });
      setUpdatedIgnoreRemoveTags(updatedIgnoreRemoveTags);

      return updatedIgnoreRemoveTags;
    }
  };

  const filterRequestStatusForRemoveHashtag = () => {
    let allTags = [];

    if (hashtagPostDeatils.hastag_suggest_post) {
      hashtagPostDeatils.hastag_suggest_post.map((e) => {
        e.suggest_tag.map((f) => {
          if (f.status === 'hold' && e.suggest_stauts === 'remove_tag') {
            return allTags.push(Object.assign(f, { userName: e.user.user_name }));
          }
        });
      });
      const counts = {};
      allTags.forEach((x) => {
        counts[x.hashtag_name] = (counts[x.hashtag_name] || 0) + 1;
      });

      let uniqueArray = [...new Map(allTags.map((v) => [v.hashtag_name, v])).values()];

      RemoveTagSuggest = uniqueArray.map((e, i) => {
        let keys = Object.keys(counts);
        keys.forEach((key) => {
          if (e.hashtag_name === key) {
            Object.assign(e, { count: counts[key] });
          }
        });
        return e;
      });
    }

    const userData = async (hashTagName) => {
      let users = [];
      await allTags.map((elem) => {
        if (elem.hashtag_name == hashTagName) {
          users.push(elem.userName);
        }

        let uniqUsersObject = Array.from(new Set(users));

        SetUser(uniqUsersObject);

        setShowuser(!showUser);
        setcurrentHaghtag(hashTagName);
        return users;
      });
    };

    const userDataCount = (hashTagName) => {
      let userCount = [];
      let uniqUsersObject = [];
      allTags.map((elem) => {
        if (elem.hashtag_name == hashTagName) {
          userCount.push(elem.userName);
        }
        uniqUsersObject = Array.from(new Set(userCount));
      });
      return uniqUsersObject.length;
    };

    return (
      <>
        {' '}
        <thead>
          <tr>
            <th className="adm-lg" colspan="4">
              Wrong tag reports{' '}
              <span className="text_primary">
                ({RemoveTagSuggest.length ? RemoveTagSuggest.length : 0})
              </span>
            </th>
          </tr>
        </thead>
        {RemoveTagSuggest.map((elem) => {
          return (
            <tbody>
              {' '}
              <tr className="tag-list-active">
                <td>
                  <u>
                    <span onClick={() => userData(elem.hashtag_name)} className="mouse_cursor">
                      {userDataCount(elem.hashtag_name) > 1
                        ? `${userDataCount(elem.hashtag_name)} users`
                        : `${userDataCount(elem.hashtag_name)} user`}
                    </span>
                  </u>
                  {showUser && currentHaghtag === elem.hashtag_name ? (
                    <div className="showUserListWrapper">
                      <ul className="showUserList ">
                        {user && user.map((userName) => <li>{userName}</li>)}
                      </ul>
                    </div>
                  ) : (
                    ' '
                  )}
                </td>
                <td>{elem.hashtag_name}</td>
                <td>active</td>
                <td className="text-right">
                  {removeReqArray &&
                    removeReqArray.map((elm) => {
                      if (elm === elem.hashtag_name) {
                        return (
                          <div className="mxBtnRight">
                            {' '}
                            <a
                              href="#"
                              className="btn btn-gray adm-btn"
                              onClick={() => {
                                handleClickUndoRemove(hashtagPostDeatils.id, elem, 'REMOVE');
                              }}
                            >
                              undo
                            </a>
                          </div>
                        );
                      }
                    })}

                  {removeIgnoreReqArray &&
                    removeIgnoreReqArray.map((elm) => {
                      if (elm === elem.hashtag_name) {
                        return (
                          <div className="mxBtnRight">
                            {' '}
                            <a
                              href="#"
                              className="btn btn-gray adm-btn"
                              onClick={() => {
                                handleClickUndoRemove(hashtagPostDeatils.id, elem, 'IGNORE');
                              }}
                            >
                              undo
                            </a>
                          </div>
                        );
                      }
                    })}

                  {updatedRemoveTags &&
                    updatedRemoveTags.map((elm) => {
                      if (elm === elem.hashtag_name) {
                        return (
                          <div className="mxBtnRight">
                            <a
                              href="#"
                              className="btn btn-gray adm-btn"
                              onClick={() => {
                                handleClickUndoRemove(hashtagPostDeatils.id, elem, 'UPDATE');
                              }}
                            >
                              undo
                            </a>
                          </div>
                        );
                      }
                    })}

                  {updatedIgnoreRemoveTags &&
                    updatedIgnoreRemoveTags.map((elm) => {
                      if (elm === elem.hashtag_name) {
                        return (
                          <div className="mxBtnRight">
                            {' '}
                            <a
                              href="#"
                              className="btn btn-gray adm-btn"
                              onClick={() => {
                                handleClickUndoRemove(hashtagPostDeatils.id, elem, 'UPDATEIGNORE');
                              }}
                            >
                              undo
                            </a>{' '}
                          </div>
                        );
                      }
                    })}

                  {removeReqArray.find((e) => e === elem.hashtag_name) ||
                  removeIgnoreReqArray.find((e) => e === elem.hashtag_name) ||
                  updatedRemoveTags.find((e) => e === elem.hashtag_name) ||
                  updatedIgnoreRemoveTags.find((e) => e === elem.hashtag_name) ? null : (
                    <div className="mxBtnRight">
                      <a
                        href="#"
                        className="btn btn-primary adm-btn"
                        onClick={() => {
                          setCurrentReportHashtag(elem.hashtag_name);
                          setreqType('remove');
                          updatedRemoveTags.push(elem.hashtag_name);
                          setUpdatedRemoveTags(updatedRemoveTags);
                        }}
                      >
                        remove tag
                      </a>
                      <a
                        href="#"
                        className="btn btn-gray adm-btn"
                        onClick={() => {
                          updatedIgnoreRemoveTags.push(elem.hashtag_name);
                          setUpdatedIgnoreRemoveTags(updatedIgnoreRemoveTags);
                          // rejectReportedTag(hashtagPostDeatils.id, elem.hashtag_name, "From remove tag")
                        }}
                      >
                        ignore
                      </a>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          );
        })}
      </>
    );
  };

  const savePost = async () => {
    return (
      (await updatedAddTags.length)
        ? localStorage.setItem(`${hashtagPostDeatils.id} Add`, [...addReqArray, ...updatedAddTags])
        : '',
      (await updatedRemoveTags.length)
        ? localStorage.setItem(`${hashtagPostDeatils.id} Remove`, [
            ...removeReqArray,
            ...updatedRemoveTags,
          ])
        : '',
      (await updatedIgnoreRemoveTags.length)
        ? localStorage.setItem(`${hashtagPostDeatils.id} RemoveIgnore`, [
            ...removeIgnoreReqArray,
            ...updatedIgnoreRemoveTags,
          ])
        : '',
      (await updatedIgnoreAddTags.length)
        ? localStorage.setItem(`${hashtagPostDeatils.id} AddIgnore`, [
            ...addIgnoreReqArray,
            ...updatedIgnoreAddTags,
          ])
        : '',
      (await updatedRemoveTagsAdmin.length)
        ? localStorage.setItem(`${hashtagPostDeatils.id} RemoveTagAdmin`, [
            ...removeTagAdminReqArray,
            ...updatedRemoveTagsAdmin,
          ])
        : '',
      await setIsShownn(false)
    );
  };

  const updatePost = async () => {
    if (
      (updatedAddTags.length || addReqArray.length) &&
      (removeReqArray.length || updatedRemoveTags.length)
    ) {
      const body = {
        suggesttag: {
          reason: 'reason',
          status: 'approved',
          add_tag: [...updatedAddTags, ...addReqArray],
          remove_tag: [...updatedRemoveTags, ...removeReqArray],
        },
      };
      await approveReportedTag(hashtagPostDeatils, body);
    } else if (updatedAddTags.length || addReqArray.length) {
      const body = {
        suggesttag: {
          reason: 'reason',
          status: 'approved',
          add_tag: [...updatedAddTags, ...addReqArray],
        },
      };
      await approveReportedTag(hashtagPostDeatils, body);
    } else if (updatedRemoveTags.length || removeReqArray.length) {
      const body = {
        suggesttag: {
          reason: 'reason',
          status: 'approved',
          remove_tag: [...updatedRemoveTags, ...removeReqArray],
        },
      };
      await approveReportedTag(hashtagPostDeatils, body);
      await setIsShownn(false);
    }

    if (
      ((updatedIgnoreRemoveTags.length || removeIgnoreReqArray.length) &&
        updatedIgnoreAddTags.length) ||
      addIgnoreReqArray.length
    ) {
      const body = {
        suggesttag: {
          reason: 'pls reject the tag',
          status: 'rejected',
          add_tag: [...updatedIgnoreAddTags, ...addIgnoreReqArray],
          remove_tag: [...updatedIgnoreRemoveTags, ...removeIgnoreReqArray],
        },
      };
      await rejectReportedTag(hashtagPostDeatils.id, body);
    } else if (updatedIgnoreRemoveTags.length || removeIgnoreReqArray.length) {
      const body = {
        suggesttag: {
          reason: 'pls reject the tag',
          status: 'rejected',
          remove_tag: [...updatedIgnoreRemoveTags, ...removeIgnoreReqArray],
        },
      };
      await rejectReportedTag(hashtagPostDeatils.id, body);
    } else if (updatedIgnoreAddTags.length || addIgnoreReqArray.length) {
      const body = {
        suggesttag: {
          reason: 'pls reject the tag',
          status: 'rejected',
          add_tag: [...updatedIgnoreAddTags, ...addIgnoreReqArray],
        },
      };
      await rejectReportedTag(hashtagPostDeatils.id, body);
    }

    if (removeTagAdminReqArray.length || updatedRemoveTagsAdmin.length) {
      await removeTagsInPost(hashtagPostDeatils, [
        ...removeTagAdminReqArray,
        ...updatedRemoveTagsAdmin,
      ]);
    }

    await localStorage.removeItem(`${hashtagPostDeatils.id} Remove`);
    await localStorage.removeItem(`${hashtagPostDeatils.id} Add`);
    await localStorage.removeItem(`${hashtagPostDeatils.id} RemoveIgnore`);
    await localStorage.removeItem(`${hashtagPostDeatils.id} AddIgnore`);
    await localStorage.removeItem(`${hashtagPostDeatils.id} RemoveTagAdmin`);

    await setIsShownn(false);
  };

  const backPage = () => {
    setIsShownn(false);
  };

  const addRemoveTags = () => {
    let allAddTags = [];
    let allRemoveTags = [];
    let adminAddedTag = [];
    if (hashtagPostDeatils.hastag_suggest_post) {
      hashtagPostDeatils.hastag_suggest_post.map((e) => {
        e.suggest_tag.map((f) => {
          if (f.status === 'approved' && e.suggest_stauts === 'approved_tag') {
            return allAddTags.push(Object.assign(f, { userName: e.user.user_name }));
          }
        });

        e.suggest_tag.map((f) => {
          if (f.status === 'approved' && e.suggest_stauts === 'remove_tag') {
            return allRemoveTags.push(Object.assign(f, { userName: e.user.user_name }));
          }
        });
      });

      const counts = {};
      allAddTags.forEach((x) => {
        counts[x.hashtag_name] = (counts[x.hashtag_name] || 0) + 1;
      });
      approveTagApproved = [...new Map(allAddTags.map((v) => [v.hashtag_name, v])).values()];
      approveTagApproved = approveTagApproved.map((elem) =>
        Object.assign(elem, { suggest_stauts: 'approved_tag' }),
      );

      const countsRemove = {};
      allRemoveTags.forEach((x) => {
        countsRemove[x.hashtag_name] = (countsRemove[x.hashtag_name] || 0) + 1;
      });
      RemoveTagApproved = [...new Map(allRemoveTags.map((v) => [v.hashtag_name, v])).values()];
      RemoveTagApproved = RemoveTagApproved.map((elem) =>
        Object.assign(elem, { suggest_stauts: 'remove_tag' }),
      );
      adminAddedTag = approveTagApproved.filter(
        (e) => !RemoveTagApproved.map((i) => i.hashtag_name).includes(e.hashtag_name),
      );
    }

    return (
      <>
        {adminAddedTag &&
          adminAddedTag.map((elem) => {
            return (
              <div className="tags-view-list">
                {removeTagAdminReqArray &&
                  removeTagAdminReqArray.map((elm) => {
                    if (elm === elem.hashtag_name) {
                      return (
                        <h5>
                          {elem.hashtag_name}

                          <a
                            href="#"
                            className="lps-link text-underline"
                            onClick={() => {
                              handleClickRemove(hashtagPostDeatils.id, elem, 'UNDOSAVED');
                            }}
                          >
                            undo
                          </a>
                        </h5>
                      );
                    }
                  })}

                {updatedRemoveTagsAdmin &&
                  updatedRemoveTagsAdmin.map((elm) => {
                    if (elm === elem.hashtag_name) {
                      return (
                        <h5>
                          {elem.hashtag_name}

                          <a
                            href="#"
                            className="lps-link text-underline"
                            onClick={() => {
                              handleClickRemove(hashtagPostDeatils.id, elem, 'UNDOUPDATE');
                            }}
                          >
                            undo
                          </a>
                        </h5>
                      );
                    }
                  })}

                {removeTagAdminReqArray.find((e) => e === elem.hashtag_name) ||
                updatedRemoveTagsAdmin.find((e) => e === elem.hashtag_name) ? null : (
                  <h5>
                    {elem.hashtag_name}
                    {elem.suggest_stauts === 'approved_tag' ? (
                      <a
                        href="#"
                        className="lps-link text-underline"
                        onClick={() => {
                          updatedRemoveTagsAdmin.push(elem.hashtag_name);
                          setUpdatedRemoveTagsAdmin(updatedRemoveTagsAdmin);
                        }}
                      >
                        remove
                      </a>
                    ) : (
                      <a href="#" className="lps-link text-underline">
                        <span onClick={() => addTagsInPost(hashtagPostDeatils, elem.hashtag_name)}>
                          add
                        </span>
                      </a>
                    )}
                  </h5>
                )}
              </div>
            );
          })}
      </>
    );
  };

  const handleClickRemove = async (id, elem, STR) => {
    if (STR === 'UNDOSAVED') {
      removeTagAdminReqArray = removeTagAdminReqArray.filter((savedTag) => {
        if (savedTag !== elem.hashtag_name) {
          return savedTag;
        }
      });

      await localStorage.removeItem(`${id} RemoveTagAdmin`);
      if (removeTagAdminReqArray.length) {
        localStorage.setItem(`${id} RemoveTagAdmin`, [removeTagAdminReqArray]);
      }

      return removeTagAdminReqArray;
    } else if (STR === 'UNDOUPDATE') {
      updatedRemoveTagsAdmin = updatedRemoveTagsAdmin.filter((savedTag) => {
        if (savedTag !== elem.hashtag_name) {
          return savedTag;
        }
      });
      setUpdatedRemoveTagsAdmin(updatedRemoveTagsAdmin);

      return updatedRemoveTagsAdmin;
    }
  };

  return (
    <>
      {''}
      {/* < ReportModel currentReport={currentReport} isShown={isShown} setIsShown={setIsShown} approveReportedTag={approveReportedTag} approveReportedTag={approveReportedTag} reqType=
                {reqType} setCurrentReport={setCurrentReport} hashName={hashName} updatedAddTags={updatedAddTags} setUpdatedAddTags={setUpdatedAddTags} updatedRemoveTags={updatedRemoveTags} setUpdatedRemoveTags={setUpdatedRemoveTags}></ReportModel> */}
      <ViewPOstModal
        isShownViewPost={isShownViewPost}
        setISShownViewPost={setISShownViewPost}
        hashtagPostDeatils={hashtagPostDeatils}
      ></ViewPOstModal>
      {hashtagPostDeatils && hashtagPostDeatils.user ? (
        <>
          <div className="mb-3">
            <Button className="btn-default" onClick={backPage} variant="outlined" color="secondary">
              Back
            </Button>
          </div>
          <section className="post-view-sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-5">
                  <article className="adm-art">
                    <h5>Edit post view</h5>
                  </article>
                </div>
                <div className="col-md-7">
                  <div className="btn-wrapper-btwn">
                    <a
                      href="#"
                      className="btn btn-primary adm-btn mw-260"
                      onClick={() => updatePost()}
                    >
                      update post
                    </a>
                    <a href="#" className="btn btn-gray adm-btn mw-260" onClick={() => savePost()}>
                      save changes
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid post-view-box">
              <div className="row mb-5">
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-md-3">
                      <article className="adm-art">
                        <h5>
                          By{' '}
                          <span className="font-regular">{hashtagPostDeatils.user.user_name}</span>
                        </h5>
                        <h5>
                          Privacy{' '}
                          <span className="font-regular">
                            {hashtagPostDeatils.user.privacy_settings}{' '}
                          </span>
                        </h5>
                      </article>
                    </div>
                    <div className="col-md-9">
                      <div className="post-form-wrapper">
                        <div className="adm-thumb-art-wrapper">
                          {hashtagPostDeatils.type === 'image' ? (
                            <figure className="adm-fig adm-fig-post">
                              <img
                                src={hashtagPostDeatils.attachments[0].photo_urls.original}
                                alt="Thumbnails"
                              />
                            </figure>
                          ) : (
                            <p className="post-desc">
                              {!viewDesc
                                ? TextTruncate({ str: hashtagPostDeatils.description, limit: 200 })
                                : hashtagPostDeatils.description}
                            </p>
                          )}

                          <article className="adm-art adm-readmore-art">
                            <p className="post-desc">
                              {hashtagPostDeatils.type === 'image'
                                ? !viewDesc
                                  ? TextTruncate({ str: hashtagPostDeatils.description, limit: 50 })
                                  : hashtagPostDeatils.description
                                : ''}
                              <a
                                href="#"
                                className="lps-link lps-link-block"
                                onClick={() => setISShownViewPost(true)}
                              >
                                view post
                              </a>
                            </p>
                          </article>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group tags-form-group">
                              <input
                                className="form-control input-border-bottom"
                                value="post tags"
                              />
                            </div>
                            {hashtagPostDeatils.hashtagPosts.map((postHashtag) => (
                              <div className="tags-view-list">
                                <h5>{postHashtag.hashtag_name}</h5>
                              </div>
                            ))}
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group tags-form-group">
                              <input
                                className="form-control input-border-bottom"
                                value="you added"
                              />
                            </div>
                            {addRemoveTags()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="table-responsive lps-mb-130">
                    <table className="table lps-table">
                      {filterRequestStatusForApproveHashtag()}
                    </table>
                  </div>
                  <div className="table-responsive">
                    <table className="table lps-table">
                      {filterRequestStatusForRemoveHashtag()}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default ReportDetails;
