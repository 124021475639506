export const ReportedHastagActionType = {
  GET_REPORTED_HASHTAGS: 'GET_REPORTED_HASHTAGS',
  GET_REPORTED_HASHTAGS_REJECTED: 'GET_REPORTED_HASHTAGS_REJECTED',
  GET_REPORTED_HASHTAGS_FULFILLED: 'GET_REPORTED_HASHTAGS_FULFILLED',

  FILTER_REPORTED_HASHTAGS: 'FILTER_REPORTED_HASHTAGS',
  FILTER_REPORTED_HASHTAGS_REJECTED: 'FILTER_REPORTED_HASHTAGS_REJECTED',
  FILTER_REPORTED_HASHTAGS_FULFILLED: 'FILTER_REPORTED_HASHTAGS_FULFILLED',

  REMOVE_REPORTED_HASHTAGS: 'REMOVE_REPORTED_HASHTAGS',
  REJECT_REPORTED_HASHTAGS: 'REJECT_REPORTED_HASHTAGS',

  GET_REPORTED_DATA: 'GET_REPORTED_DATA',
  GET_REPORTED_DATA_REJECTED: 'GET_REPORTED_DATA_REJECTED',
  GET_REPORTED_DATA_FULFILLED: 'GET_REPORTED_DATA_FULFILLED',

  GET_POST_DETAILS: 'GET_POST_DETAILS',
  GET_POST_DETAILS_REJECTED: 'GET_POST_DETAILS_REJECTED',
  GET_POST_DETAILS_FULFILLED: 'GET_POST_DETAILS_FULFILLED',

  ADD_HASHTAGS_POST: 'ADD_HASHTAGS_POST',
  ADD_HASHTAGS_POST_REJECTED: 'ADD_HASHTAGS_POST_REJECTED',
  ADD_HASHTAGS_POST_FULFILLED: 'ADD_HASHTAGS_POST_FULFILLED',

  REMOVE_HASHTAGS_POST: 'REMOVE_HASHTAGS_POST',
  REMOVE_HASHTAGS_POST_REJECTED: 'REMOVE_HASHTAGS_POST_REJECTED',
  REMOVE_HASHTAGS_POST_FULFILLED: 'REMOVE_HASHTAGS_POST_FULFILLED',
};
