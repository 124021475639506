import axios from 'axios';
import { API_VIRSION } from 'config';

export const getApprovals = (params) => axios.get(`${API_VIRSION}/admin/approval`, { params });

export const approvePost = (id) => axios.put(`${API_VIRSION}/admin/approval/${id}/accept`);

export const rejectPost = (id) => axios.put(`${API_VIRSION}/admin/approval/${id}/reject`);

export const userFirstTimeApproved = (body, userId) =>
  axios.put(`${API_VIRSION}/user/${userId}/time_approval`, body);

export const reApplyApproval = (body, approvalId) =>
  axios.put(`${API_VIRSION}/admin/approval/${approvalId}/reappeal`, body);

export const getPastApprovalReq = (userId) => axios.get(`${API_VIRSION}/admin/approval/${userId}`);
