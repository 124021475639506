// Hashtag api
import * as codeAPI from '../../api/CouponCodeAPI';

import { CouponCodeActionType } from '../constants/CouponCodeActionType';

export const getCouponCodes = async ({ body, dispatch }) =>
  dispatch({
    type: CouponCodeActionType.GET_COUPON_CODE,
    payload: await codeAPI.getCouponCodes(body),
  });

export const createCouponCode = async ({ body, dispatch }) =>
  dispatch({
    type: CouponCodeActionType.ADD_COUPON_CODE,
    payload: await codeAPI.createCouponCode(body),
  });
