import { FeaturedAdActionType } from 'store/constants/FeaturedAdActionType';

const featuredadInitialState = {
  featuredAdList: [],
  status: 'init',
  count: 1,
};

const FeaturedAdReducer = (state = featuredadInitialState, action = {}) => {
  let featuredAd = [];
  switch (action.type) {
    case FeaturedAdActionType.GET_FEATURED_AD_LIST:
      return {
        ...state,
        featuredAdList: action.payload.data.featuredAd,
        count: 0,
        status: 'added',
      };

    case FeaturedAdActionType.ADD_FEATURED_AD:
      return {
        ...state,
        featuredAdList: [action.payload.hashtag, ...state.hashtagList],
      };

    case FeaturedAdActionType.UPDATE_FEATURED_AD:
      return {
        ...state,
        featuredAdList: featuredAd,
        status: 'init',
      };

    case FeaturedAdActionType.FEATURED_CONFIG:
      return {
        ...state,
        featuredAdList: action.payload,
      };

    default:
      return state;
  }
};

export { FeaturedAdReducer, featuredadInitialState };
