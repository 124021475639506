// report api
import * as reportAPI from 'api/reportedPostsAPI';
import { ReportedPostsActionType } from 'store/constants/ReportedPostsActionType';

export const getReportedPosts = async ({ params, dispatch }) =>
  dispatch({
    type: ReportedPostsActionType.GET_REPORTED_POSTS,
    payload: await reportAPI.getReportedPosts(params),
  });

export const deleteReportedPost = async ({ body, id, dispatch }) =>
  dispatch({
    type: ReportedPostsActionType.DELETE_REPORTED_POSTS,
    payload: await reportAPI.deleteReportedPost(id, body),
  });

export const revertReportedPost = async ({ id, dispatch }) =>
  dispatch({
    type: ReportedPostsActionType.REVERT_REPORTED_POSTS,
    payload: await reportAPI.revertReportedPost(id),
  });

export const getExportedUsersData = async ({ params, dispatch }) => {
  const res = await reportAPI.getReportedPosts(params);
  dispatch({
    type: ReportedPostsActionType.GET_USERS_DATA_FULFILLED,
    payload: res,
  });
  return res;
};

export const filterReportedPosts = async ({ params, dispatch }) => {
  dispatch({
    type: ReportedPostsActionType.FILTER_REPORTED_POSTS_FULFILLED,
    payload: await reportAPI.filterReportedPosts(params),
  });
};
