import React, { useState, useContext } from 'react';

import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import * as commonService from '../../../../utils/CommonService.js';

import { store } from 'store';
import PersonIcon from '@material-ui/icons/Person';
const ProfileDropdown = ({
  StyledMenu,
  StyledMenuItem,
  ListItemText,
  ListItemIcon,
  Button,
  history,
}) => {
  const globalState = useContext(store);
  const { userAuthDispatch } = globalState.dispatch;
  const [profileDropDown, setProfileDropDown] = useState(null);

  const handleProfileClick = (event) => {
    setProfileDropDown(event.currentTarget);
  };

  const handleClose = () => {
    setProfileDropDown(null);
  };

  const handleLogout = (confirm) => {
    if (confirm) {
      commonService.isLoading.onNext(true);
      userAuthDispatch({ type: 'REMOVE_AUTH_TOKEN', payload: { authToken: null } });
      commonService.forSuccess('Sign out successfully!', 'Success');
      history.push('/login');
      setTimeout(() => commonService.isLoading.onNext(false), 500);
    }
    commonService.isDialogOpen.onNext(false);
  };

  const logout = () => {
    commonService.isDialogOpen.onNext({
      open: true,
      data: {
        message: 'Are you sure you want to Sign out?',
      },
      cancelText: 'Cancel',
      confirmText: 'Okay',
      onConfirm: handleLogout,
      onCancel: () => commonService.isDialogOpen.onNext(false),
    });
  };

  const menuItems = [
    {
      Icon: KeyboardReturnIcon,
      handleClick: logout,
      title: 'Sign out',
    },
  ];

  const styleMenu = ({ handleClick, Icon, title, index }) => {
    return (
      <StyledMenuItem key={index} onClick={() => handleClick()}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={title} />
      </StyledMenuItem>
    );
  };

  return (
    <li className="profile-menu">
      <Button
        aria-haspopup="true"
        variant="contained"
        className="profile-icon p-0 m-0"
        onClick={handleProfileClick}
      >
        <PersonIcon color="secondary" />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={profileDropDown}
        keepMounted
        open={Boolean(profileDropDown)}
        onClose={handleClose}
      >
        {menuItems.map((menuItem, index) => styleMenu({ ...menuItem, index }))}
      </StyledMenu>
    </li>
  );
};

export default ProfileDropdown;
