// FeaturedAd api
import * as featuredAPI from '../../api/FeaturedAdAPI';

import { FeaturedAdActionType } from '../constants/FeaturedAdActionType';

export const getFeaturedAds = async ({ body, dispatch }) =>
  dispatch({
    type: FeaturedAdActionType.GET_FEATURED_AD_LIST,
    payload: await featuredAPI.getFeaturedAd(body),
  });

export const createFeaturedAd = async ({ body, dispatch }) =>
  dispatch({
    type: FeaturedAdActionType.ADD_FEATURED_AD,
    payload: await featuredAPI.createFeaturedAd(body),
  });

export const updateFeaturedAd = async ({ body, id, dispatch }) =>
  dispatch({
    type: FeaturedAdActionType.UPDATE_FEATURED_AD,
    payload: await featuredAPI.updateFeaturedAd(body, id),
  });

export const config = async ({ body, dispatch }) =>
  dispatch({
    type: FeaturedAdActionType.FEATURED_CONFIG,
    payload: await featuredAPI.fetchUploadUrl(body),
  });
