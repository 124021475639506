import React, { useState, useEffect, useCallback } from 'react';
import CommonTable from 'components/shared/common-table/CommonTable';
import { Checkbox } from '@material-ui/core';
import moment from 'moment';

const CouponCodeTable = ({ couponcodeDispatch, couponcodeList }) => {
  const [couponcodeData, setCouponCodeData] = useState([]);

  const keys = ['id', 'code', 'email', 'active'];

  const couponcodeTableData = useCallback(() => {
    return couponcodeList.map((code) => {
      return [
        code.id,
        code.code,
        code.email,
        <Checkbox
          className="p-0"
          defaultChecked={code.active_status ? true : false}
          color="primary"
          disabled
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />,
      ];
    });
  }, [couponcodeList]);

  useEffect(() => {
    let couponcodeRowData = couponcodeTableData(couponcodeList);
    if (JSON.stringify(couponcodeRowData) !== JSON.stringify(couponcodeData)) {
      setCouponCodeData(couponcodeRowData);
    }
  }, [couponcodeTableData, couponcodeList, couponcodeData]);
  return <CommonTable data={couponcodeData} keys={keys}></CommonTable>;
};

export default CouponCodeTable;
