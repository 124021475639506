import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Button, ButtonGroup, Icon } from '@material-ui/core';

import CommonTable from 'components/shared/common-table/CommonTable';

const UserTable = ({
  totalCount,
  pagination,
  setPagination,
  usersDispatch,
  userList,
  handleDelete,
}) => {
  const [userData, setUserData] = useState([]);
  const history = useHistory();

  //  const toggleActive = useCallback(
  //   (checked, user) => {
  //     //user.active_status = checked ? 1 : 0;
  //     UsersAction.toggleActive({ body: { active: checked } , id: user.id, dispatch: usersDispatch });
  //   },
  //   [UsersAction, usersDispatch]
  // );

  const keys = [
    'id',
    'user name',
    'email',
    'Active Status',
    'Approval Status',
    'privacy',
    'action',
  ];

  const userTableData = useCallback(() => {
    if (userList.length) {
      return userList.map((user) => {
        return [
          user.id,
          <div className="d-inline user-img">
            <Avatar
              alt="Natacha"
              className="float-left mr-1"
              src={user.photo_urls && user.photo_urls.medium}
            />
            <span>{user.user_name}</span>
          </div>,
          user.email,
          user.active_status,
          user.approval_status ? user.approval_status : 'not submitted',
          user.privacy_settings,
          <ButtonGroup variant="text" color="secondary" aria-label="text primary button group">
            <Button
              disabled={user.active_status === 'deleted'}
              onClick={() => history.push(`/users/${user.id}/details`)}
            >
              <Icon color="primary">exit_to_app_sharp</Icon>
            </Button>
            ,
            <Button onClick={() => handleDelete(user)}>
              <Icon color="secondary">delete_sharp</Icon>
            </Button>
          </ButtonGroup>,
        ];
      });
    } else if (totalCount > 1) {
      return [['', '', '', <strong>No result found</strong>, '', '', '']];
    } else {
      return [];
    }
  }, [userList, totalCount, history, handleDelete]);

  useEffect(() => {
    let userRowData = userTableData(userList);
    if (JSON.stringify(userRowData) !== JSON.stringify(userData)) {
      setUserData(userRowData);
    }
  }, [userTableData, userList, userData]);
  return (
    <CommonTable
      totalCount={totalCount}
      customPagination={pagination}
      setCustomPagination={setPagination}
      data={userData}
      keys={keys}
    ></CommonTable>
  );
};

export default UserTable;
