import { ReportedPostsActionType } from 'store/constants/ReportedPostsActionType';

const reportInitialState = {
  reports: [],
  count: 0,
  status: 'init',
};

const ReportedPostsReducer = (state = reportInitialState, action = {}) => {
  switch (action.type) {
    case ReportedPostsActionType.GET_REPORTED_POSTS:
      return {
        ...state,
        reports: action.payload.reports,
        count: action.payload.report_count,
        status: 'added',
      };

    case ReportedPostsActionType.FILTER_REPORTED_POSTS_FULFILLED:
      return {
        ...state,
        reports: action.payload.reports,
        status: 'updated_reports',
        count: action.payload.report_count,
      };

    default:
      return state;
  }
};

export { ReportedPostsReducer, reportInitialState };
