import axios from 'axios';
import { API_VIRSION } from 'config';
// Users api
export const getUsers = (params) => axios.get(`${API_VIRSION}/admin/user`, { params });

export const getUser = (id) => axios.get(`${API_VIRSION}/admin/user/${id}`);

export const approvedUser = (body, id) =>
  axios.put(`${API_VIRSION}/admin/user/${id}/approve`, body);

export const deactivateUser = (id) => axios.put(`${API_VIRSION}/admin/user/${id}/deactivate`);

export const filterUsers = (params) =>
  axios.get(`${API_VIRSION}/admin/user`, {
    params,
  });

export const deleteUser = (id) => axios.delete(`${API_VIRSION}/admin/user/${id}`);
