import React, { useContext } from 'react';

import { store } from 'store';

import SideMenu from './components/SideMenu';
/*
 all links contained in sidebar
 */
const links = [
  {
    link: '/',
    title: 'Hashtag',
    exact: true,
    icon: 'fa-hashtag',
  },
  {
    link: '/users',
    title: 'Users',
    exact: true,
    icon: 'fas fa-users',
  },
  {
    link: '/action-items',
    title: 'Action-Items',
    exact: true,
    icon: 'fa fa-list-alt',
  },
  {
    link: '/featured-ads',
    title: 'Featured Ad',
    exact: true,
    icon: 'fa-bullhorn',
  },
  {
    link: '/coupon-codes',
    title: 'Coupon Code',
    exact: true,
    icon: 'fa fa-code',
  },
  {
    link: '/moderation',
    title: 'Moderation',
    exact: true,
    icon: 'fa fa-crosshairs',
  },
  {
    link: '/reported-posts',
    title: 'Reported Posts',
    exact: true,
    icon: 'fa fa-file',
  },
  {
    link: '/analytics',
    title: 'Analytics',
    exact: true,
    icon: 'fa fa-chart-line',
  },
  {
    link: '/edit-post-tags',
    title: 'Edit post tags',
    exact: true,
    icon: 'fas fa-edit',
  },
];
/*
 * params set sidebar toggle and responsive sidebar toggle
 * */
const Sidebar = ({ setResSidebarToggle, ...props }) => {
  const globalState = useContext(store);
  const { userAuthState } = globalState.state;
  return (
    <section className="left_sidebar_wrapper">
      <div className="cross-icon" onClick={() => setResSidebarToggle(false)}>
        <span className="fa fa-times"></span>
      </div>
      <div className="left_Sidebar_Sec">
        <aside className="app-sidebar">
          <div className="app-sidebar-mini__heading   p-1 text-center">
            <h5>{userAuthState.currentUser.user_name}</h5>
          </div>
          <div className="app-sidebar_Heading  p-1 ml-3">
            <h5>{userAuthState.currentUser.user_name}</h5>
          </div>
          <ul className="app-menu">
            {links.map((link, index) => (
              <SideMenu key={index} {...link} />
            ))}
          </ul>
        </aside>
      </div>
    </section>
  );
};

export default Sidebar;
