import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { store } from 'store';
import * as FeaturedAction from 'store/actions/FeaturedAdActions';
import FeaturedAdTable from './FeaturedAdList.js';

const FeaturedAdList = () => {
  const history = useHistory();
  const defaultPagination = {
    page: 1,
    limit: 10,
    approved_status: true,
  };
  const globalState = useContext(store);
  const { featuredadDispatch } = globalState.dispatch;
  const { featuredAdList, status, count } = globalState.state.featuredadState;
  const [rowData, setRowData] = useState([]);
  const [pagination, setDefaultPagination] = useState(defaultPagination);
  const [filterParams] = useState({});

  useEffect(() => {
    if (!rowData.length && !featuredAdList.length && status === 'init') {
      FeaturedAction.getFeaturedAds({ body: { ...pagination }, dispatch: featuredadDispatch });
    }
  }, [rowData, featuredAdList, status, featuredadDispatch, pagination]);

  useEffect(() => {
    if (rowData.length !== featuredAdList.length || status !== 'init') {
      const rows = featuredAdList;
      setRowData(rows);
    }
  }, [rowData, status, featuredAdList]);

  const setPagination = (params) => {
    setDefaultPagination({ ...pagination, ...params });
    FeaturedAction.getFeaturedAds({
      body: { ...pagination, ...params, ...filterParams },
      dispatch: featuredadDispatch,
    });
  };
  return (
    <>
      <div className="p-2">
        <div className="row">
          <div className="col-md-8"></div>
          <div className="col-md-4 text-right p-2">
            <Button
              variant="contained"
              color="primary"
              className="btn-theme-primary mr-2"
              onClick={(e) => history.push('/featured-ad/new')}
            >
              Add New
            </Button>
          </div>
        </div>
        <FeaturedAdTable
          totalCount={count}
          pagination={pagination}
          setPagination={setPagination}
          featuredAdList={rowData}
          featuredadDispatch={featuredadDispatch}
        ></FeaturedAdTable>
      </div>
    </>
  );
};

export default FeaturedAdList;
