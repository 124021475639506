import React, { useState, useEffect, useContext } from 'react';
//import {Link} from "react-router-dom";
import { store } from 'store';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import * as SuggestedHashtagAction from 'store/actions/SuggestedHashtagActions';
import SuggestedHashtagTable from './SuggestedHashtagList.js';

const SuggestedHashtagList = () => {
  const history = useHistory();
  const defaultPagination = {
    page: 1,
    limit: 10,
    approved_status: false,
  };
  const globalState = useContext(store);
  const { suggestedhashtagDispatch } = globalState.dispatch;
  const { suggestedHashtagList, status, count } = globalState.state.suggestedhashtagState;
  const [rowData, setRowData] = useState([]);
  const [pagination, setDefaultPagination] = useState(defaultPagination);
  const [filterParams] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      SuggestedHashtagAction.getSuggestedHashtags({
        body: { ...pagination },
        dispatch: suggestedhashtagDispatch,
      });
    }
  }, [rowData, suggestedHashtagList, status, suggestedhashtagDispatch, pagination, loaded]);

  useEffect(() => {
    if (rowData.length !== suggestedHashtagList.length || status !== 'init') {
      const rows = suggestedHashtagList;
      setRowData(rows);
    }
  }, [rowData, status, suggestedHashtagList]);

  const setPagination = (params) => {
    setDefaultPagination({ ...pagination, ...params });
    SuggestedHashtagAction.getSuggestedHashtags({
      body: { ...pagination, ...params, ...filterParams },
      dispatch: suggestedhashtagDispatch,
    });
  };

  const approveHashtag = (hashtag) => {
    SuggestedHashtagAction.approveHashtag({
      id: hashtag.id,
      dispatch: suggestedhashtagDispatch,
    });
  };
  return (
    <>
      <div className="p-2">
        <div className="mb-2">
          <Button
            className="btn-default"
            onClick={() => history.push(`/`)}
            variant="outlined"
            color="secondary"
          >
            Back
          </Button>
        </div>
        <SuggestedHashtagTable
          totalCount={count}
          pagination={pagination}
          setPagination={setPagination}
          approveHashtag={approveHashtag}
          suggestedHashtagList={rowData}
          suggestedhashtagDispatch={suggestedhashtagDispatch}
          SuggestedHashtagAction={SuggestedHashtagAction}
        ></SuggestedHashtagTable>
      </div>
    </>
  );
};

export default SuggestedHashtagList;
